import React from "react";
import {
  API_GET,
  API_POST,
  userRoles,
  InviteeStatus,
} from "../../services/api.service";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Typography from "@material-ui/core/Typography";
import Table from "components/Table/Table.jsx";
import Loader from "components/Loader/Loader.jsx";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Person from "@material-ui/icons/Person";
import Avatar from "@material-ui/core/Avatar";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { ButtonGroup, DialogContent } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { PhoneInTalkRounded, PolicySharp, VpnKeyOutlined } from "@material-ui/icons";
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import InfoBox from "../../components/InfoIcon/InfoIcon";

// import InfoIcon from "@material-ui/icons/Info";
// import Typography from "@material-ui/core/Typography";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class MyTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: props.history,
      errors: {},
      user: null,
      userInfo: null,
      // AllMembersByUserId: null,
      notify: {
        open: false,
        message: "",
        color: "success",
      },
      avbUserCount: 0,
      openchangemanager: false,
      openchangerole: false,
      openchangepassword: false,
      selectedMember: null,
      openchangewebphone: false,
      newPassword: null,
      IsWebPhone: false,
      empId: null,
      agentMobile: null,
      callType: null,
      first_name: null,
      setting: null,
      activeTab: null,
      openPhone: false,
      openPermission: false,
      userPhone: null,
    };
    this.addMember = this.addMember.bind(this);
    this.userRoles = ["manager", "agent", "field_exc"];
  }
  async addMember(e) {
    e.preventDefault();

    const fields = ["name", "email", "admin_id"];
    const formElements = e.target.elements;
    const formValues = fields
      .map((field) => ({
        [field]: formElements.namedItem(field).value.trim(),
      }))
      .reduce((current, next) => ({ ...current, ...next }));

    let registerRequest;
    try {
      registerRequest = await API_POST("register/addMember", formValues);
      console.log(registerRequest);
      debugger;

      if (registerRequest && registerRequest.success) {
        this.setState({
          notify: {
            open: true,
            message: registerRequest.messages.success,
            color: "success",
          },
        });
        //this.loadUserInfo(this.state.user.id);
        this.GetAllMembersByUserId(this.state.user.id);
        //debugger;
        document.getElementById("frmAddMember").reset();
      } else {
        this.setState({
          notify: {
            open: true,
            message: registerRequest.messages.success,
            color: "danger",
          },
        });
      }
    } catch (error) {
      console.log(error);
      //registerRequest = response;
    }

    debugger;
  }

  async promoteMember(e, element) {
    e.preventDefault();
    let member = this.state.selectedMember;
    if (!window.confirm("Are you sure! you wanna promote " + member.username)) {
      return;
    }
    try {
      let registerRequest = await API_POST("register/changeRole", {
        user_id: member.user_id,
        role: element,
      });
      console.log(registerRequest);
      if (registerRequest && registerRequest.success) {
        this.setState({
          notify: {
            open: true,
            message: "User role changed successfully.",
            color: "success",
          },
          openchangerole: false,
        });
        //this.loadUserInfo(this.state.user.id);
        this.GetAllMembersByUserId(this.state.user.id);
      }
    } catch ({ response }) {
      //registerRequest = response;
    }
  }

  async componentDidMount() {
    let getSessionRequest;
    getSessionRequest = await API_GET(`get-session`);
    //Page Authorization
    if (getSessionRequest && getSessionRequest.success) {
      this.setState({ user: getSessionRequest.userInfo, permissionPages: getSessionRequest.allPermissionPages, userPagePermission: getSessionRequest.userpermissionPages });

      //this.loadUserInfo(getSessionRequest.userInfo.id);
      this.GetAllMembersByUserId(getSessionRequest.userInfo.id);

      this.getSetting();

    }

  }

  async loadUserInfo(userid) {
    let userInfo = await API_GET(`register/userinfo?id=${userid}`);
    if (userInfo.success) {
      // this.setState({
      //   notify: {
      //     open: true,
      //     message: 'Loaded',
      //     color: 'success'
      //   }
      // });
      this.setState({ userInfo: userInfo.data });
    } else {
      this.setState({ userInfo: null });
    }
  }

  async GetAllMembersByUserId(userid) {

    let userInfo = await API_GET(`register/GetAllMembersByUserId?id=${userid}`);
    if (userInfo.success) {
      debugger;
      this.setState({ userInfo: userInfo.data });
      if (userInfo.data.avbUserCount) {
        this.setState({
          avbUserCount: userInfo.data.avbUserCount,
        });
      }
    } else {
      this.setState({ userInfo: null });
    }
  }

  async handleListItemClick(e, manager) {
    try {
      let registerRequest = await API_POST("register/changeManager", {
        user_id: this.state.selectedMember.user_id,
        reporter_id: manager.user_id,
      });

      console.log(registerRequest);
      if (registerRequest && registerRequest.success) {
        this.setState({
          notify: {
            open: true,
            message: "Manager changed.",
            color: "success",
          },
        });
        //this.loadUserInfo(this.state.user.id);
        this.GetAllMembersByUserId(this.state.user.id);
      }
    } catch ({ response }) {
      console.log(response);
    }
    this.handleClose();
  }

  async handleClose(e) {
    this.setState({
      openchangemanager: false,
    });
  }

  async changeManager(e, element) {
    e.preventDefault();
    this.setState({
      openchangemanager: true,
      selectedMember: element,
    });
  }
  async changeRole(e, element) {
    e.preventDefault();
    this.setState({
      openchangerole: true,
      selectedMember: element,
    });
  }
  async changePassword(e, element) {
    e.preventDefault();
    this.setState({
      openchangepassword: true,
      selectedMember: element,
      first_name: element.first_name,
    });
  }

  async changePhone(e, element) {
    e.preventDefault();
    this.setState({
      openPhone: true,
      selectedMember: element,
      userPhone: element.phone,
    });
  }



  async changePermission(e, element) {
    e.preventDefault();
    let request = await API_POST(
      `register/getPermissions?user_id=${element.id}`
    );



    this.setState({
      openPermission: true,
      selectedMember: element,
      selectedPersona: request.data.data && request.data.data.length && request.data.data[0].personaId,
      persona: request.data.persona
    });
  }


  async changeWebPhone(e, element) {
    e.preventDefault();

    this.setState({
      openchangewebphone: true,
      selectedMember: element,
      agentMobile: element.agentMobile,
      IsWebPhone: element.IsWebPhone,
      callType: element.callType,
      empId: element.empId
    });
  }

  savePermission = async (e) => {

    try {
      let registerRequest = await API_POST("register/updatePermissions", {
        data: this.state.selectedPersona,
        user: this.state.selectedMember,
      });

      this.setState({
        openPermission: false,
        notify: {
          open: true,
          message: "Data Updated",
          color: "success",
        },
      });

    } catch (e) {

    }

  }

  async sentEmail(e, element) {
    e.preventDefault();
    try {
      let request = await API_GET(
        `email/sendCode?user_id=${element.to_user_id}`
      );
      console.log(request);
      if (request && request.success) {
        this.setState({
          notify: {
            open: true,
            message: request.messages.success,
            color: "success",
          },
        });
        //this.loadUserInfo(this.state.user.id);
        this.GetAllMembersByUserId(this.state.user.id);
      }
    } catch ({ response }) {
      console.log(response);
    }
  }

  saveWebPhone = async (e) => {
    this.saveWebPhoneInDb();
  };

  saveWebPhoneInDb = async () => {
    let webPhoneRequest;

    webPhoneRequest = await API_POST("register/updateWebPhone", {
      IsWebPhone: this.state.IsWebPhone,
      empId: this.state.empId,
      agentMobile: this.state.agentMobile,
      callType: this.state.callType,
      user: this.state.selectedMember.id,
    });


    if (webPhoneRequest && webPhoneRequest.data.update) {
      this.setState({
        openchangewebphone: false,
        notify: {
          open: true,
          message: "Data Updated",
          color: "success",
        },
      });
      this.GetAllMembersByUserId(this.state.user.id);
    } else if (webPhoneRequest && webPhoneRequest.data.error) {
      this.setState({
        notify: {
          open: true,
          message: webPhoneRequest.data.msg,
          color: "danger",
        },
      });

    } else {
      this.setState({
        notify: {
          open: true,
          message: "Something went wrong",
          color: "danger",
        },
      });
    }

  };


  savePhone = async (e) => {
    this.savePhoneInDB(this.state.userPhone);
  };

  savePassword = async (e) => {
    this.savePasswordInDB(this.state.newPassword);
  };


  savePhoneInDB = async (phone) => {
    let registerRequest;

    registerRequest = await API_POST("register/ResetPhone", {
      phone,
      user: this.state.selectedMember.id,
    });

    if (registerRequest && registerRequest.data.update) {
      this.setState({
        openPhone: false,
        notify: {
          open: true,
          message: "Phone Changed",
          color: "success",
        },
      });
    } else {
      this.setState({
        openPhone: false,
        notify: {
          open: true,
          message: "Something went wrong",
          color: "danger",
        },
      });
    }
    this.GetAllMembersByUserId(this.state.user.id);
  };

  savePasswordInDB = async (password) => {
    let registerRequest;
    let firstName = this.state.first_name;

    registerRequest = await API_POST("register/ResetPassword", {
      password,
      first_name: firstName,
      user: this.state.selectedMember.username,
    });

    if (registerRequest && registerRequest.data.update) {
      this.setState({
        openchangepassword: false,
        notify: {
          open: true,
          message: "Record Updated",
          color: "success",
        },
      });
      this.GetAllMembersByUserId(this.state.user.id);
    } else {
      this.setState({
        openchangepassword: false,
        notify: {
          open: true,
          message: "Something went wrong",
          color: "danger",
        },
      });
    }
  };

  async sendApprove(e, element) {
    e.preventDefault();
    try {
      let request = await API_GET(`email/verify?code=${element.code}`);
      console.log(request);
      if (request && request.success) {
        this.setState({
          notify: {
            open: true,
            message: request.messages.success,
            color: "success",
          },
        });
        //this.loadUserInfo(this.state.user.id);
        this.GetAllMembersByUserId(this.state.user.id);
      } else {
        this.setState({
          notify: {
            open: true,
            message: request.messages.error,
            color: "danger",
          },
        });
      }
    } catch ({ response }) {
      console.log(response);
    }
  }

  async changeUserType(e, element, Type) {
    debugger;
    let IsRemove = true;
    if (Type == 0) {
      IsRemove = await this.ChkAssignedLead(element.username);
    }
    if (IsRemove) {

      let registerRequest = await API_POST("register/changeUserState", {
        Type: Type,
        id: element.user_id,
        username: element.username,
      });
      debugger;
      if (registerRequest && registerRequest.data.update) {

        this.setState({
          notify: {
            open: true,
            message: "Save Successfully",
            color: "success",
          },
        });
        this.GetAllMembersByUserId(this.state.user.id);
      } else {
        this.setState({
          notify: {
            open: true,
            message: "Your user limit is over.",
            color: "danger",
          },
        });
      }
    } else {
      this.setState({
        notify: {
          open: true,
          message: "Please change the assignment before remove member.",
          color: "danger",
        },
      });
    }
  }
  async ChkAssignedLead(username) {
    let Request;
    Request = await API_POST("action/getCount", {
      root: "prospects",
      con: {
        // cid,
        assignTo: username,
      },
    });
    if (Request && Request.status == 200 && Request.data > 0) {
      return false;
    } else {
      return true;
    }
  }



  async getSetting() {


    let Request = await API_POST("action/getdata", {
      root: "Configuration",
      con: {
        context: 'notification',
        admin: this.state.user && this.state.user.admin,
      },
    });



    let settings = {};
    for (let i = 0; i < Request.data.length; i++) {
      settings[Request.data[i].name] = Request.data[i];
    }


    console.log(settings);

    this.setState({ setting: settings });

    return true;

  }

  async changePermissionAction(e, id) {

    this.setState({ selectedPersona: e.target.value });

  }

  async setSettings(e, value) {


    console.log(e);

    await API_POST("action/upsert", {
      root: "Configuration",
      querydata: {
        name: value.name,
        context: 'notification',
        admin: this.state.user && this.state.user.admin,
      },
      body: {
        // cid,
        ...value,
        context: 'notification',
        userId: this.state.user && this.state.user.id,
        admin: this.state.user && this.state.user.admin,
      },
    });

    await this.getSetting();
    return true;

  }



  render() {
    const { classes, name, email } = this.props;
    const { errors, user, userInfo, setting } = this.state;

    // if (!userInfo) {
    //   return null;
    // }

    var managers = [];
    var managerslist = [];
    var tableData = [];

    if (userInfo && userInfo.teams_membership) {
      for (let index = 0; index < userInfo.teams_membership.length; index++) {
        const element = userInfo.teams_membership[index];
        if (element.role == "owner") {
          managerslist.push({
            user_id: element.user_id,
            username: element.username,
          });
        }

        if (element.role == "manager") {
          managers.push(element.username);
          managerslist.push({
            user_id: element.user_id,
            username: element.username,
          });
        }
        if (element.is_staff == 1) {
          tableData.push([
            element.username,
            element.first_name,
            element.phone,
            userRoles[element.role],
            <a onClick={(e) => this.changeManager(e, element)}>
              {element.Manager}
            </a>,
            element.last_login
              ? window.moment(element.last_login).fromNow()
              : "NA",
            element.last_activity
              ? window.moment(element.last_activity).fromNow()
              : "NA",
            element.expireon
              ? window.moment(element.expireon).format('DD-MMM-YYYY')
              : "NA",
            <ButtonGroup>

              {element.is_active && element.is_active == 1 ? (
                <Button
                  color="success"
                  size="sm"
                  justIcon
                  title="Active"
                  onClick={(e) => this.changeUserType(e, element, 0)}
                >
                  <VisibilityIcon />

                </Button>

              ) : (

                <Button
                  color="danger"
                  size="sm"
                  justIcon
                  title="De-Active"
                  onClick={(e) => this.changeUserType(e, element, 1)}
                >
                  <VisibilityOffIcon />
                </Button>

              )},
              <Button
                color="transparent"
                size="sm"
                justIcon
                title="Change Role"
                onClick={(e) => this.changeRole(e, element)}
              >
                <VerifiedUserIcon />
              </Button>,
              <Button
                size="sm"
                color="transparent"
                justIcon
                title="Change Password"
                onClick={(e) => this.changePassword(e, element)}
              >
                <VpnKeyIcon />
              </Button>,
              <Button
                size="sm"
                color="transparent"
                justIcon
                title="Configuration"
                onClick={(e) => this.changeWebPhone(e, element)}
              >
                <SettingsIcon />
              </Button>,
              <Button
                size="sm"
                color="transparent"
                justIcon
                title="Change Phone No."
                onClick={(e) => this.changePhone(e, element)}
              >
                <PhoneCallbackIcon />

              </Button>,
              <Button
                size="sm"
                color="transparent"
                justIcon
                title="Permissions"
                onClick={(e) => this.changePermission(e, element)}
              >

                <PolicySharp />

              </Button>,
            </ButtonGroup>
          ]);
        }
      }
    }

    let Invitees = null;
    if (userInfo && userInfo.invitees) {
      var InviteestableData = [];
      for (let index = 0; index < userInfo.invitees.length; index++) {
        const element = userInfo.invitees[index];

        if (element.is_staff == 1) {
          InviteestableData.push([
            element.username,
            InviteeStatus[element.status],
            element.sent ? window.moment(element.sent).fromNow() : "NA",
            element.expireon &&
            window.moment(element.expireon).format("DD-MMM-YYYY HH:mm:ss"),
            <Button
              color="danger"
              size="sm"
              onClick={(e) => this.sentEmail(e, element)}
            >
              Send
            </Button>,
            element.status === 2 ? (
              <Button
                size="sm"
                color="success"
                onClick={(e) => this.sendApprove(e, element)}
              >
                Approve
              </Button>
            ) : null,
          ]);
        }
      }
      Invitees = (
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="transparent">
              <h3>Invitees</h3>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={[
                  "User",
                  "Status",
                  "Invited At",
                  "Expire On",
                  "Action",
                  "",
                ]}
                tableData={InviteestableData}
              />
            </CardBody>
          </Card>
        </GridItem>
      );
    }

    return (
      <div>
        <Dialog
          onClose={this.handleClose.bind(this)}
          aria-labelledby="simple-dialog-title"
          open={this.state.openchangemanager}
        >
          <DialogTitle id="simple-dialog-title">Change Manager</DialogTitle>
          <List>
            {managerslist.map((item) => (
              <ListItem
                button
                onClick={(e) => this.handleListItemClick(e, item)}
                key={item.user_id}
              >
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.username} />
              </ListItem>
            ))}
          </List>
        </Dialog>

        <Dialog
          onClose={() => this.setState({ openchangerole: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.openchangerole}
        >
          <DialogTitle id="simple-dialog-title">Change User Role</DialogTitle>
          <List>
            {this.userRoles.map((item) => (
              <ListItem
                button
                onClick={(e) => this.promoteMember(e, item)}
                key={item}
              >

                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <i />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={userRoles[item]} />
              </ListItem>
            ))}
          </List>
        </Dialog>

        <Dialog
          onClose={() => this.setState({ openchangewebphone: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.openchangewebphone}
        >
          <DialogTitle id="simple-dialog-title">
            Change WebPhone{" "}
          </DialogTitle>
          <DialogContent>
            <span>
              for User:{" "}
              {this.state &&
                this.state.selectedMember &&
                this.state.selectedMember.username}

            </span>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>

                <CustomInput
                  labelText="Web Phone"
                  id="IsWebPhone"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName,
                  }}
                  inputProps={{
                    type: "checkbox",
                    checked: this.state.IsWebPhone,
                    required: true,
                    onChange: (e) => {
                      this.setState({ IsWebPhone: e.target.checked });
                    },
                  }}
                />

                <CustomInput
                  labelText="EmployeeId/ Ext/ Agent Mobile"
                  id="EmployeeId"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName,
                  }}
                  inputProps={{

                    type: "text",
                    value: this.state.empId,
                    autocomplete: "off",
                    onChange: (e) => {
                      this.setState({ empId: e.target.value });
                    },
                  }}
                />
                {/* <CustomInput
                  labelText="Agent Mobile"
                  id="agentmobile"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName,
                  }}
                  inputProps={{
                    type: "text",
                    value: this.state.agentMobile,
                    autocomplete: "off",
                    required: true,
                    onChange: (e) => {
                      this.setState({ agentMobile: e.target.value });
                    },
                  }}
                /> */}

                <CustomInput
                  labelText="Call Type"
                  id="calltype"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName,
                  }}
                  inputProps={{
                    type: "text",
                    value: this.state.callType,
                    autocomplete: "off",
                    required: true,
                    onChange: (e) => {
                      this.setState({ callType: e.target.value });
                    },
                  }}
                />
                <p>If you are using beTyphon autodialer (WEBPHONE/MOBILE) or else you can use provider value.</p>
                {/* <CustomInput
                  labelText="Call Type"
                  id="calltype"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName,
                  }}

                  inputProps={{
                    value: this.state.callType,
                    type: "select",
                    options: [
                      {
                        value: 'Mobile To Mobile',
                        key: 'MOBILE'
                      },
                      {
                        value: 'On Extention',
                        key: 'WEBPHONE'
                      }
                    ],
                    autocomplete: "off",
                    onChange: (e) => {
                      this.setState({ callType: e.target.value });
                    },
                  }}
                /> */}

              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color="primary"
                  simple
                  size="sm"
                  onClick={this.saveWebPhone}
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  simple
                  size="sm"
                  onClick={() => this.setState({ openchangewebphone: false })}
                >
                  Cancel
                </Button>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>

        <Dialog
          onClose={() => this.setState({ openchangepassword: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.openchangepassword}
        >
          <DialogTitle id="simple-dialog-title">
            Change User Password{" "}
          </DialogTitle>
          <DialogContent>
            <span>
              for User:{" "}
              {this.state &&
                this.state.selectedMember &&
                this.state.selectedMember.username}
            </span>






            <GridContainer justify="center">


              <CustomInput
                labelText="User Name "
                id="username"
                formControlProps={{
                  fullWidth: true,
                  className: classes.formControlClassName,
                }}
                inputProps={{
                  type: "text",
                  value: this.state.first_name,
                  required: true,
                  onChange: (e) => {
                    this.setState({ first_name: e.target.value });
                  },
                }}
              />


              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="New Password"
                  id="NewPassword"
                  error={errors.password || errors.invalidEmailOrPassword}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName,
                  }}
                  inputProps={{
                    type: "password",
                    value: this.state.newPassword,
                    autocomplete: "off",
                    required: true,
                    onChange: (e) => {
                      this.setState({ newPassword: e.target.value });
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>


              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color="primary"
                  simple
                  size="sm"
                  onClick={this.savePassword}
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  simple
                  size="sm"
                  onClick={() => this.setState({ openchangepassword: false })}
                >
                  Cancel
                </Button>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>



        <Dialog
          onClose={() => this.setState({ openPermission: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.openPermission}
        >
          <DialogTitle id="simple-dialog-title">
            Permission {" "}
          </DialogTitle>
          <DialogContent>
            <span>
              for User:{" "}
              {this.state &&
                this.state.selectedMember &&
                this.state.selectedMember.username}
            </span>

            <GridContainer justify="center">


              <GridItem xs={12} sm={12} md={12}>


                {this.state.persona && (
                  <>


                    <CustomInput
                      labelText={"select Persona"}
                      id="NewPhone"
                      formControlProps={{
                        fullWidth: true,
                        className: classes.formControlClassName,
                      }}
                      inputProps={{
                        type: "select",
                        value: this.state.selectedPersona,
                        type: "select",
                        options: this.state.persona.map((v) => { return { key: v.id, value: v.name } }),
                        onChange: (e) => {
                          this.changePermissionAction(e)
                        },
                      }}
                    />







                  </>
                )

                }

              </GridItem>


              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color="primary"
                  simple
                  size="sm"
                  onClick={this.savePermission}
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  simple
                  size="sm"
                  onClick={() => this.setState({ openPermission: false })}
                >
                  Cancel
                </Button>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>




        <Dialog
          onClose={() => this.setState({ openPhone: false })}
          aria-labelledby="simple-dialog-title"
          open={this.state.openPhone}
        >
          <DialogTitle id="simple-dialog-title">
            Change User Phone{" "}
          </DialogTitle>
          <DialogContent>
            <span>
              for User:{" "}
              {this.state &&
                this.state.selectedMember &&
                this.state.selectedMember.username}
            </span>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="New Phone"
                  id="NewPhone"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.formControlClassName,
                  }}
                  inputProps={{
                    type: "Numbers",
                    value: this.state.userPhone,
                    autocomplete: "off",
                    required: true,
                    onChange: (e) => {
                      this.setState({ userPhone: e.target.value });
                    }
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  color="primary"
                  simple
                  size="sm"
                  onClick={this.savePhone}
                >
                  Save
                </Button>
                <Button
                  color="primary"
                  simple
                  size="sm"
                  onClick={() => this.setState({ openPhone: false })}
                >
                  Cancel
                </Button>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>


        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={userInfo && userInfo.avbUserCount > 0 ? 6 : 12}
          >
            <Card>
              <CardHeader color="transparent">
                <h3>Manage Teams</h3>
                <GridItem style={{ float: "right", marginTop: "-40px" }}>

                  <InfoBox name={'MyTeam.ManageTeam'} />

                </GridItem>
                {/* <p>
                  {userInfo &&
                    userInfo.teams_team &&
                    userInfo.teams_team.description}
                </p> */}
                <span>
                  You have created{" "}
                  <b>
                    {userInfo && userInfo.TotalUserCount - userInfo.avbUserCount}
                  </b>{" "}
                  out of <b>{userInfo && userInfo.TotalUserCount}</b> members.
                </span>
              </CardHeader>
              <CardBody style={{ minHeight: '200px' }}>
                {!userInfo ? (
                  <Loader />
                ) : (
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <b>Owners -</b> <span> {user && user.username}</span>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <b>Account Expire on -</b> <span> {user && window.moment(user.expireon).format("DD-MMM-YYYY")}

                      </span>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>

                      <b> Managers - </b>{" "}
                      {managers.length > 0 ? managers.join(",") : "None"}

                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>

                      <b> Customer Success Manager: - </b>
                      {user && user.allocatedTo != null &&
                      <>{user.allocatedToRM.first_name}
                      
                      {user.allocatedToRM.agentMobile && <>({<a href={'tel:'+user.allocatedToRM.agentMobile}></a>})</>} </>
                      }
                      <br />
                      {
                        user && user.admin == 'info@techmet.in' && <><a onClick={(e) => this.state.history.push("/admin/RMAllocated")} > Allocate RM </a></>
                      }

                    </GridItem>

                  </GridContainer>
                )}
              </CardBody>
            </Card>
          </GridItem>





          {userInfo && userInfo.avbUserCount > 0 && (
            <GridItem xs={12} sm={12} md={6}>
              <form onSubmit={this.addMember} id="frmAddMember">
                <Card>
                  <CardHeader color="transparent">
                    <h3>Add Team</h3>
                  </CardHeader>
                  <CardBody style={{ minHeight: '200px' }}>
                    {!userInfo ? (
                      <Loader />
                    ) : (
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Name"
                            id="name"
                            error={errors.name}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              required: true,
                              defaultValue: name,
                              name: "name",
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <CustomInput
                            labelText="Email address"
                            id="email-address"
                            error={errors.email}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              required: true,
                              defaultValue: email,
                              name: "email",
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} />
                      </GridContainer>
                    )}
                  </CardBody>
                  {userInfo ? (
                    <CardFooter>
                      <input
                        type="hidden"
                        id="admin_id"
                        name="admin_id"
                        value={user && user.id}
                      />

                      <Button type="submit" color="primary" size="sm">
                        SEND INVITE E-MAIL
                      </Button>
                    </CardFooter>
                  ) : null}
                </Card>
              </form>
            </GridItem>
          )}


          {Invitees}

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="transparent">
                <h3>Members</h3>
              </CardHeader>
              <CardBody style={{ minHeight: '200px' }}>
                {!userInfo ? (
                  <Loader />
                ) : (
                  <Table
                    tableHeaderColor="primary"
                    tableHead={[
                      "User",
                      "name",
                      "phone",
                      "Role",
                      "Manager",
                      "Last Login",
                      "Last Activity",
                      "expireOn",
                      "Action",
                      "",
                      "",
                    ]}
                    tableData={tableData}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(MyTeam);
