import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import _, { forEach, values } from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {
  API_GET,
  API_POST,
  userRoles,
  InviteeStatus,
} from "../../services/api.service";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Tab,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import { Link } from "react-router-dom";

function SubscriberMember(props) {

  const { showMember, allocatedUsers = [], changeAllocated = false, showCustomerLiveData, history, user, changeUserCategory, changeUserType, changeExpiry, changeCount, changeUserStatus, changeDetails, moveRecordsToArchive } = props;
  const [Count, setCount] = useState(0);
  const [ReferBy, setrefereBy] = useState(null);
  const [allocatedTo, setAllocatedTo] = useState(null);
  const [phone, setPhone] = useState(null);
  const [company, setCompany] = useState(null);
  const [data, setData] = useState([]);
  const [userStatus, setUserStatus] = useState([]);
  const categoryStatusOption = [
    { value: 'Not Followed', name: 'Not Followed' },
    { value: 'Happy Customers', name: 'Happy Customers' },
    { value: 'Recording Customer', name: 'Recording Customer' },
    { value: 'Irregular', name: 'Irregular' },
    { value: 'Less Usage', name: 'Less Usage' },
    { value: '30 days observation', name: '30 days observation' },
    { value: 'Lets Focus', name: 'Lets Focus' },
    { value: 'Followups', name: 'Followups' },
    { value: 'Not Connected', name: 'Not Connected' }
  ];
  const democategoryStatus = [
    { value: 'Followups', name: 'Followups' },
    { value: 'Not Followed', name: 'Not Followed' },
    { value: 'Not Connected', name: 'Not Connected' }
  ];
  const [categoryStatus, setCategoryStatus] = useState(categoryStatusOption);
  const [userStatusOptions, setUserStatusOptions] = useState([]);
  const [openChangeExpiry, setOpenChangeExpiry] = useState(false);
  const [allocateUserTeam, setallocateUserTeam] = useState([]);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [openChangeCategory, setOpenChangeCategory] = useState(false);
  const [openChangeAllocation, setOpenChangeAllocation] = useState(false);
  const [openChangeUserAccount, setOpenChangeUserAccount] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [filterAcount, setFilterAccount] = useState('Paid account');
  const [filterlastActive, setFilterLastActive] = useState(false);
  const [filterlastNonActive, setFilterNonLastActive] = useState(true);
  const [selectedMember, setSelectedMember] = useState({});
  const [customerRecords, setCustomerRecords] = useState(null);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));

  const customerColumn = [
    {
      name: "Expire On",
      selector: row => row.expireon,
      width: "100px",
      cell: (row) => (
        <span>
          {row.expireon ? window.moment(row.expireon).format("DD-MMM-YYYY") : 'N/A'}
        </span>
      ),
    },
    {
      name: "Category",
      selector: row => row.category,
      width: "100px",
      cell: (row) =>
        (filterAcount === "Paid account" ||
          filterAcount === "Paid expired" ||
          filterAcount === "Demo account") ? (
          <a
            onClick={(e) => {
              setOpenChangeCategory(true);
              setSelectedMember(row);
            }}
            style={{ cursor: "pointer" }}
          >
            {row && row.category ? row.category : "Change Category"}
          </a>
        ) : null,
    },
    {
      name: "Company",
      selector: row => row.company,
      cell: (row) => (
        <span>
          <a onClick={() => handleURL(row)} style={{ cursor: "pointer" }}>
            {row.company || 'N/A'}
          </a>
        </span>
      ),
      width: "250px",
    },
    {
      name: "Activity Count",
      selector: row => row.todayActivityCount,
      width: "80px",
    },
    {
      name: "Today Active",
      selector: row => row.todayActive,
      width: "70px",
      cell: (row) => (
        <span>
          {row.todayActive ? 'Active' : 'Not Active'}
        </span>
      ),
    },
    {
      name: "Name",
      selector: row => row.name,
      width: "200px",
      cell: (row) => row.name || 'N/A',
    },
    {
      name: "Password",
      selector: row => row.password,
      width: "auto",
    },
    {
      name: "Phone",
      selector: row => row.phone,
      width: "150px",
      cell: (row) => row.phone || 'N/A',
    },
    {
      name: "Disposition",
      selector: row => row.disposition,
      width: "170px",
      cell: (row) => row.disposition || 'N/A',
    },
    {
      name: "Last Update",
      selector: row => row.lastUpdate,
      width: "150px",
      cell: (row) => (
        <>{row.lastUpdate && window.moment(row.lastUpdate).format("DD-MMM-YYYY")}</>
      ),
    },
    {
      name: "Max User",
      selector: row => row.max_users,
      width: "70px",
      cell: (row) => row.max_users || 'N/A',
    },
    {
      name: "Active User",
      selector: row => row.activeCount,
      width: "80px",
      cell: (row) => row.activeCount || 'N/A',
    },
    {
      name: "Activity Count",
      selector: row => row.todayActivityCount,
      width: "80px",
      cell: (row) => row.todayActivityCount || 'N/A',
    },
    {
      name: "Refer By",
      selector: row => row.refer_by,
      width: "200px",
      cell: (row) => row.refer_by || 'N/A',
    },
    {
      name: "Email",
      selector: row => row.email,
      width: "270px",
      cell: (row) => row.email || 'N/A',
    },
    {
      name: "Owner Id",
      selector: row => row.id,
      width: "100px",
      cell: (row) => row.id || 'N/A',
    },

    {
      name: "Status",
      selector: row => row.status,
      cell: (row) =>
        row.role === "owner" ? (
          <a
            onClick={(e) => {
              setOpenChangeStatus(true);
              setSelectedMember(row);
            }}
            style={{ cursor: "pointer" }}
          >
            {row.status ? row.status : "Change Status"}
          </a>
        ) : null,
    },
  ];



  const columns = [
    {
      name: "Edit Details",
      cell: (row) => (
        row.role == "owner" ?
          <Button
            color={'success'}
            size="sm"
            onClick={(e) => {
              setOpenEdit(true);
              setSelectedMember(row);
              setrefereBy(row.refer_by);
              setAllocatedTo(row.allocatedTo);
              setPhone(row.phone);
              setCompany(row.company);
            }}
          >
            Edit
          </Button> : ""
      ),
    },
    {
      name: "Allocated RM",
      cell: (row) => (
        (changeAllocated && row.role == "owner") ? <a
          onClick={(e) => {
            setOpenChangeAllocation(true);
            setSelectedMember(row);
          }}
        >
          {row && row.allocatedUser ? row.allocatedUser : "change RM Allocation"}
        </a> : row.allocatedUser
      ),
    },
    {
      name: "Archive Prospects",
      cell: (row) => (
        row.role == "owner" && row.expired == 1 ?
          <Button
            color={'success'}
            size="sm"
            onClick={(e) => {
              moveRecordsToArchive(row.username)
            }}
          >
            Archive
          </Button> : ""
      ),
    },

    {
      name: "Action",
      grow: true,
      cell: (row) => (
        <span>


          {row.is_active && row.is_active == 1 ? (
            <Button
              color="danger"
              size="sm"
              onClick={(e) => changeUserType(e, row, 0)}
            >
              {" "}
              DeActive
            </Button>
          ) : (
            <Button
              color="success"
              size="sm"
              onClick={(e) => changeUserType(e, row, 1)}
            >
              Active
            </Button>
          )}
        </span>
      ),
    },
    {
      name: "Expired On",
      cell: (row) => (
        <Button
          color={row.expired == 0 ? 'success' : 'danger'}
          size="sm"
          onClick={(e) => {
            setOpenChangeExpiry(true);
            setSelectedMember(row);
          }}
        >
          {row.expireon && window.moment(row.expireon).format("DD-MMM-YYYY")}
        </Button>
      ),
    },
    {
      name: "User Count",
      cell: (row) => (
        row.role == "owner" ? <Button
          color="success"
          size="sm"
          onClick={(e) => {
            setOpenChangeUserAccount(true);
            setSelectedMember(row);
          }}
        >
          {row ? row.max_users : 0}
        </Button> : ""
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        row.role == "owner" ? <a
          onClick={(e) => {
            setOpenChangeStatus(true);
            setSelectedMember(row);
          }}
        >
          {row && row.status ? row.status : "change status"}
        </a> : ""
      ),
    },
    {
      name: "Owner",
      selector: row => row.admin,
      width: "200px",
      cell: (row) => row.admin || 'N/A',
    },
    {
      name: "username",
      selector: row => row.username,
      width: "270px",
      cell: (row) => row.username || 'N/A',
    },

    {
      name: "Password",
      cell: (row) => <span>{row.password}</span>,
    },
    {
      name: "Role",
      selector: row => row.Role,
      width: "270px",
      cell: (row) => row.Role || 'N/A',
    },
    {
      name: "Created On",
      cell: (row) => (
        <span>
          {row.date_joined &&
            window.moment(row.date_joined).format("DD-MMM-YYYY HH:mm")}
        </span>
      ),
    },
    {
      name: "Last Login",
      cell: (row) => (
        <span>
          {row.last_login &&
            window.moment(row.last_login).format("DD-MMM-YYYY HH:mm")}
        </span>
      ),
    },
    {
      name: "Last Activity",
      cell: (row) => (
        <span>
          {row.last_activity &&
            window.moment(row.last_activity).format("DD-MMM-YYYY HH:mm")}
        </span>
      ),
    },

    {
      name: "name",
      selector: row => row.name,
      cell: (row) => row.name || 'N/A',
    },
    {
      name: "company",
      selector: row => row.company,
      cell: (row) => row.company || 'N/A',
    },
    {
      name: "phone",
      selector: row => row.phone,
      cell: (row) => row.phone || 'N/A',
    },
    {
      name: "Company Size",
      selector: row => row.company_size,
      cell: (row) => row.company_size || 'N/A',
    },
    {
      name: "Signup Source",
      selector: row => row.signup_source,
      cell: (row) => row.signup_source || 'N/A',
    },
    {
      name: "Refer By",
      selector: row => row.refer_by,
      cell: (row) => row.refer_by || 'N/A',
    },
    {
      name: "Account Status",
      selector: row => row.status,
      cell: (row) => row.status || 'N/A',
      width: "270px",
    },
    {
      name: "Lead Source",
      cell: (row) => (
        <i title={row.lead_source}>
          {row.lead_source}
        </i>
      ),
    },
  ];

  const usercolumns = [
    {
      name: "Allocated RM",
      cell: (row) => (
        row.allocatedUser
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        row.status
      ),
    },
    {
      name: "Owner",
      selector: "admin",
      width: "270px",
    },
    {
      name: "username",
      selector: "username",
      width: "270px",
    },
    {
      name: "Password",
      cell: (row) => <span>{row.password}</span>,
    },
    {
      name: "Role",
      selector: "role",
    },
    {
      name: "Created On",
      cell: (row) => (
        <span>
          {row.date_joined &&
            window.moment(row.date_joined).format("DD-MMM-YYYY HH:mm")}
        </span>
      ),
    },
    {
      name: "Last Login",
      cell: (row) => (
        <span>
          {row.last_login &&
            window.moment(row.last_login).format("DD-MMM-YYYY HH:mm")}
        </span>
      ),
    },
    {
      name: "Last Activity",
      cell: (row) => (
        <span>
          {row.last_activity &&
            window.moment(row.last_activity).format("DD-MMM-YYYY HH:mm")}
        </span>
      ),
    },


    {
      name: "name",
      selector: "name",
    },
    {
      name: "company",
      selector: "company",
    },
    {
      name: "phone",
      selector: "phone",
    },
    {
      name: "Company Size",
      selector: "company_size",
    },
    {
      name: "Signup Source",
      selector: "signup_source",
    },
    {
      name: "Refer By",
      selector: "refer_by",
    },
    {
      name: "Account Status",
      selector: "status",
      width: "270px",
    },
    {
      name: "Lead Source",
      cell: (row) => (
        <i title={row.lead_source}>
          {row.lead_source}
        </i>
      ),
    },
  ];


  const GetAllMembersByUserId = async (userid = 40) => {

    let userInfo = await API_GET(`register/GetAllMembersByUserId?id=8`);
    if (userInfo.success) {
      setallocateUserTeam(userInfo.data.teams_membership.map((v) => {
        return { key: v.id, value: v.email };
      }))
    } else {

    }
  }

  const handleURL = async (data) => {

    try {

      window.location.href = '/admin/viewprospect/' + data.prospectId;
      //console.log(registerRequest && registerRequest.data);


    } catch (e) {

      console.log(e)
    }

  }

  const loadCampaigns = async () => {
    debugger;
    const { user } = props;

    let registerRequest;
    let IsActive = true;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { admin: user && user.admin, IsActive },
        //cols: {}
      });



      if (registerRequest && registerRequest.status == 200) {
        setUserStatus(registerRequest.data);
        let statusOptions = [];
        for (let i = 0; i < registerRequest.data.length; i++) {
          let element = registerRequest.data[i];
          statusOptions.push({
            key: element.name,
            value: element.name
          })

        }
        setUserStatusOptions(statusOptions);

      }

    } catch (error) {
      console.log(error);
    }
  };
  const findActiveSubscriber = async () => {

    if (!props.user) {
      return true;
    }
    if (props.user && props.user.role != 'owner' && allocatedUsers.length == 0) {
      return true;
    }


    let getRecords = await API_POST("report/customerReport", {
      cons: {
        createdOn: {
          $gte: new Date(startDate),
          $lt: new Date(endDate),
        }
      },
      root: 'CallHistory',
      filter: { status: filterAcount, user: allocatedUsers }
    });


    if (filterlastActive) {

      getRecords.data = getRecords.data.filter((v) => {
        if (v.todayActive) {
          return true;
        }
        return false;
      })

    }

    if (filterlastNonActive) {
      getRecords.data = getRecords.data.filter((v) => {
        if (!v.todayActive) {
          return true;
        }
        return false;
      })
    }

    setCustomerRecords(getRecords.data);
    // console.log(getRecords.data)



  }


  useEffect(() => {

    findActiveSubscriber()

  }, [filterAcount, filterlastActive, filterlastNonActive]);

  useEffect(() => {
    if (props.ActiveUsers && props.ActiveUsers.length > 0) {
      debugger;
      //findActiveSubscriber(props.ActiveUsers)
      setData(props.ActiveUsers);

    }
  }, [props.ActiveUsers]);
  useEffect(() => {
    loadCampaigns();
    GetAllMembersByUserId(props.user);
  }, [props && props.user]);

  const onChangeUserAllocation = async (e, element) => {
    setOpenChangeCategory(false);
    try {
      //console.log("ttttttttttt",selectedMember,element)
      //await changeUserCategory(e, selectedMember, element.name);
      let registerRequest = await API_POST("register/updateUserData", {
        id: selectedMember.id,
        updateQuery: { allocatedTo: element.id }
      });
    } catch (e) {
      console.log(e)
    }
  }
  const onChangeExpiry = async (e) => {
    debugger;
    console.log(selectedMember);
    setOpenChangeExpiry(false);
    changeExpiry(e, selectedMember);
  };




  const onChangeDetails = async (e) => {
    debugger;
    selectedMember.refer_by = ReferBy;
    selectedMember.phone = phone;
    selectedMember.company = company;
    selectedMember.company = company;
    selectedMember.allocatedTo = allocatedTo;
    console.log(selectedMember);
    setOpenEdit(false);
    changeDetails(e, selectedMember);
  };




  const onChangeCount = async (e) => {
    debugger;
    selectedMember.max_users = parseInt(Count);
    setOpenChangeUserAccount(false);
    changeCount(e, selectedMember);
  };




  const onChangeUserCategory = async (e, element) => {

    setOpenChangeCategory(false);
    console.log("gggggggg", selectedMember, element.name)
    debugger;
    try {
      if (await changeUserCategory(e, selectedMember, element.name)) {
        //selectedMember.status = element;
      }
    } catch (e) {
      console.log(e)
    }

  }


  const onChangeUserStatus = async (e, element) => {

    setOpenChangeStatus(false);
    console.log("gggggggg", selectedMember, element.name)
    debugger;
    try {
      if (await changeUserStatus(e, selectedMember, element.name)) {
        //selectedMember.status = element;
      }
    } catch (e) {
      console.log(e)
    }

  }

  return (
    <>


      <Dialog
        onClose={() => setOpenChangeAllocation(false)}
        aria-labelledby="simple-dialog-title"
        open={openChangeAllocation}
      >
        <DialogTitle id="simple-dialog-title">Change Allocation</DialogTitle>
      </Dialog>

      <Dialog
        onClose={() => setOpenChangeStatus(false)}
        aria-labelledby="simple-dialog-title"
        open={openChangeStatus}
      >
        <DialogTitle id="simple-dialog-title">Change User Status</DialogTitle>
        <List>
          {userStatus.map((item) => (
            <ListItem
              button
              onClick={(e) => onChangeUserStatus(e, item)}
              key={item}
            >
              {/* <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <i />
                </Avatar>
              </ListItemAvatar> */}
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Dialog>

      <Dialog
        onClose={() => setOpenChangeCategory(false)}
        aria-labelledby="simple-dialog-title"
        open={openChangeCategory}
      >
        <DialogTitle id="simple-dialog-title">Change Category</DialogTitle>
        <List>
          {categoryStatus.map((item) => (
            <ListItem
              button
              onClick={(e) => onChangeUserCategory(e, item)}
              key={item}
            >
              {/* <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <i />
                </Avatar>
              </ListItemAvatar> */}
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Dialog>


      <Dialog
        onClose={() => setOpenChangeExpiry(false)}
        aria-labelledby="simple-dialog-title"
        open={openChangeExpiry}
      >
        <DialogTitle id="simple-dialog-title">Change User Expiry </DialogTitle>
        <DialogContent>
          <span>for User: {selectedMember && selectedMember.username}</span>
          <br />
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                id="date"
                label="Expire On"
                type="date"
                defaultValue={
                  selectedMember.expireon &&
                  window.moment(selectedMember.expireon).format("DD-MMM-YYYY")
                }
                onChange={(e) => {
                  selectedMember.expireon = e.target.value;
                }}
                style={{ width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button color="primary" simple size="sm" onClick={onChangeExpiry}>
                Save
              </Button>
              <Button
                color="primary"
                simple
                size="sm"
                onClick={() => setOpenChangeExpiry(false)}
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => setOpenChangeUserAccount(false)}
        aria-labelledby="simple-dialog-title"
        open={openChangeUserAccount}
      >
        <DialogTitle id="simple-dialog-title">Change User Count </DialogTitle>
        <DialogContent>
          <span>for User: {selectedMember && selectedMember.username}</span>
          <br />
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Count"
                id="Count"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  pattern: "[0-9]*",
                  maxLength: 4,
                  type: 'number',
                  required: true,
                  defaultValue: selectedMember && selectedMember.max_users,
                  //placeholder:'Enter Member count',
                  name: "Count",
                  //value: Count,
                  onChange: (e) => {
                    setCount(e.target.value);
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button color="primary" simple size="sm" onClick={onChangeCount}>
                Save
              </Button>
              <Button
                color="primary"
                simple
                size="sm"
                onClick={() => setOpenChangeUserAccount(false)}
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => setOpenEdit(false)}
        aria-labelledby="simple-dialog-title"
        open={openEdit}
      >
        <DialogTitle id="simple-dialog-title">Change User Details </DialogTitle>
        <DialogContent>
          <span>for User: {selectedMember && selectedMember.username}</span>
          <br />

          <GridContainer justify="center">

          </GridContainer>

          <GridContainer justify="center">

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Allocated To"
                id="allocatedTo"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  type: 'select',
                  required: true,
                  defaultValue: allocatedTo,
                  //placeholder:'Enter Member count',
                  name: "allocatedTo",
                  options: allocateUserTeam,

                  //value: Count,
                  onChange: (e) => {
                    console.log(e.target.value)
                    setAllocatedTo(e.target.value);
                  },
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Refer By"
                id="refer"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  type: 'text',
                  required: true,
                  defaultValue: selectedMember && selectedMember.refer_by,
                  //placeholder:'Enter Member count',
                  name: "refer",
                  //value: Count,
                  onChange: (e) => {
                    setrefereBy(e.target.value);
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Phone"
                id="refer"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  type: 'Number',
                  required: true,
                  defaultValue: selectedMember && selectedMember.phone,
                  //placeholder:'Enter Member count',
                  name: "phone",
                  //value: Count,
                  onChange: (e) => {
                    setPhone(e.target.value);
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Company"
                id="refer"
                formControlProps={{
                  fullWidth: true,
                }}
                labelProps={{ shrink: true }}
                inputProps={{
                  type: 'text',
                  required: true,
                  defaultValue: selectedMember && selectedMember.company,
                  //placeholder:'Enter Member count',
                  name: "company",
                  //value: Count,
                  onChange: (e) => {
                    setCompany(e.target.value);
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Button color="primary" simple size="sm" onClick={onChangeDetails}>
                Save
              </Button>
              <Button
                color="primary"
                simple
                size="sm"
                onClick={() => setOpenEdit(false)}
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>


      {["owner", "manager"].indexOf(user && user.role) > -1 && showMember &&
        <Card>
          <CardHeader color="transparent">
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <h3>
                  Members
                  <span style={{ float: "right", fontSize: "15px" }} />
                </h3>
              </GridItem>
            </GridContainer>
          </CardHeader>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <DataTableExtensions
                columns={(user && user.role == 'owner') ? columns : usercolumns}
                data={data || []}
                export={true}
                exportHeaders={true}
                print={false}
              >
                <DataTable
                  columns={(user && user.is_superuser) ? columns : usercolumns}
                  data={data || []}
                  pagination
                  striped
                  highlightOnHover
                  pointerOnHover
                />
              </DataTableExtensions>
            </GridItem>
          </GridContainer>
        </Card>}

      {showCustomerLiveData && <Card>

        <CardHeader color="transparent">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h3>
                Customer Realtime Data
                <span style={{ float: "right", fontSize: "15px" }} />
              </h3>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>


              <Box sx={{ bgcolor: 'background.paper', width: '100%', typography: 'body1' }} style={{ "background": "rgb(38 80 119)", "width": "100%" }}>
                <TabContext value={filterAcount}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, n) => { setFilterAccount(n) }} aria-label="lab API tabs example" indicatorColor="secondary">
                      <Tab label="Paid account" value="Paid account" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                      <Tab label="Paid expired" value="Paid expired" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                      <Tab label="Demo account" value="Demo account" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                      <Tab label="Internal account" value="Internal account" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                      <Tab label="Duplicate" value="Duplicate" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                      <Tab label="ALL Account" value="All Account" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                    </TabList>
                  </Box>
                </TabContext>
              </Box>


              {
                /*

                <select name="filter" value={filterAcount} onChange={setFilterAccount(this.value)}>
                  <options value={"Paid Acount"}>Paid Acount </options>
                  <options value={"Demo Acount"}>Demo Acount </options>
                  <option value={"Paid expired"}>Paid Expired </option>
                  <option value={"Duplicate"}>Duplicate </option>
                  <option value={"Internal Acount"}>Internal Acount </option>

                </select>

                */
              }


            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                id={"body_variable_value_"}
                labelText={"Active"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  type: "checkbox",
                  value: filterlastActive,
                  //name: "last_activity",
                  onChange: (e) => {
                    setFilterLastActive(e.target.checked)
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                id={"body_variable_value_2"}
                labelText={"Non Active"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  type: "checkbox",
                  value: filterlastNonActive,
                  //name: "last_activity",
                  onChange: (e) => {
                    setFilterNonLastActive(e.target.checked)
                  },
                }}
              />

            </GridItem>
          </GridContainer>
        </CardHeader>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <DataTableExtensions
              columns={customerColumn}
              data={customerRecords || []}
              export={true}
              exportHeaders={true}
              print={false}
            >
              <DataTable
                columns={customerColumn}
                data={customerRecords || []}
                pagination
                striped
                highlightOnHover
                pointerOnHover
              />
            </DataTableExtensions>
          </GridItem>
        </GridContainer>






      </Card>
      }




    </>
  );
}

export default SubscriberMember;
