import React from "react";
import GridItem from "../Grid/GridItem.jsx";
import CustomInput from "../CustomInput/CustomInput.jsx";

class GenerateField extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      const {
        value,
        onChange,
        id,
        options,
        label,
        keyValuePair,
        type = "select",
        disabled = false,
      } = this.props;
  
      return (
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText={label}
            id
            labelProps={{ shrink: true }}
            formControlProps={{
              fullWidth: true,
              multiline: true,
            }}
            customOptions={options}
            inputProps={{
              required: false,
              value,
              defaultValue: value,
              checked: value,
              name: id,
              id: id,
              type: type,
              onChange,
              disabled,
              options: keyValuePair,
            }}
          />
        </GridItem>
      );
    }
  }

  export default GenerateField;
  