// src/components/ReportFilters.js
import React, { useState } from 'react';
import { TextField, Button, Grid } from '@material-ui/core';

const ReportFilters = ({ onFilterChange }) => {
    const [filters, setFilters] = useState({});

    const handleInputChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const handleApplyFilters = () => {
        onFilterChange(filters);
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <TextField
                    name="startDate"
                    label="Start Date"
                    type="date"
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item>
                <TextField
                    name="endDate"
                    label="End Date"
                    type="date"
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item>
                <TextField
                    name="category"
                    label="Category"
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item>
                <Button variant="contained" onClick={handleApplyFilters}>
                    Apply Filters
                </Button>
            </Grid>
        </Grid>
    );
};

export default ReportFilters;