// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import GroupIcon from '@material-ui/icons/Group';
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
import Register from "@material-ui/icons/GroupAdd";
import Login from "@material-ui/icons/LockOpen";
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import FacebookLeads from "views/FacebookLeads/FacebookLeads.jsx";
import FacebookPage from "views/FacebookLeads/FacebookPage.jsx";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import CallingSetupList from 'views/CallingSetup/CallingSetupList.jsx';
import CallingSetup from 'views/CallingSetup/CallingSetup.jsx';

import UserProfile from "views/UserProfile/UserProfile.jsx";
import MyTeam from "views/MyTeam/MyTeam.jsx";
import Subscribers from "views/Subscribers/Subscribers.jsx";
import TableList from "views/TableList/TableList.jsx";
import Typography from "views/Typography/Typography.jsx";
import Icons from "views/Icons/Icons.jsx";
import Forms from "views/Forms/Main.jsx";
import Maps from "views/Maps/Maps.jsx";
import NotificationsPage from "views/Notifications/Notifications.jsx";
import CampaignsPage from "views/Campaigns/Campaigns.jsx";
import ManageCampaigns from "views/Campaigns/ManageCampaigns.jsx";
import AddProspect from "views/Prospects/AddProspect.jsx";
import ListProspects from "views/Prospects/ListProspects.jsx";
import ViewProspect from "views/Prospects/ViewProspect.jsx";
import CRMView from "views/CRMView/CRMView.jsx";
//import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.jsx";
// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.jsx";
// core components/views for Auth layout
import LoginPage from "views/Pages/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import CampainMoreInfo from "./views/Campaigns/CampainMoreInfo";
import Activity from "./views/Activity/Activity";
import Workload from "./views/Reports/Workload";
// import AgentTracker from "./views/Reports/AgentTracker";
// import CallTracker from "./views/Reports/CallTracker";
import ProspectHistory from "./views/ProspectHistory/ProspectHistory";
import Schedule from "./views/Schedule/Schedule";
import ForgetPassword from "./views/Pages/ForgetPassword";
import CreatePassword from "./views/Pages/CreatePassword";
import ChangePassword from "./views/ChangePassword/ChangePassword";
import CustActivity from "./views/Activity/CustActivity";
import Files from "./views/Campaigns/Files";

import Templates from "./views/CommTemplates/Templates";
import EmailTemplate from "./views/CommTemplates/EmailTemplate";
import SMSTemplate from "./views/CommTemplates/SMSTemplate";
import WhatsAppTemplate from "./views/CommTemplates/WhatsAppTemplate";
import Integrations from "./views/Integrations/Integrations";
import SignUpSuccess from "./views/Pages/SignUpSuccess";
import WebHooks from "./views/Integrations/WebHooks";
import AddNotification from "./views/Notifications/AddNotification";

import SMSSetupList from 'views/SMSSetup/SMSSetupList.jsx';
import SMSSetup from 'views/SMSSetup/SMSSetup.jsx';
import WhatsAppSetupList from 'views/WhatsAppSetup/WhatsAppSetupList.jsx';
import WhatsAppSetup from 'views/WhatsAppSetup/WhatsAppSetup.jsx';
import ConfigurationPage from "./views/Configurations/Configuration";
import ReportStats from "./views/ReportStats/reportsStats";
import ReportDetails from "./views/ReportStats/reportDetails";
import { TrendingUpRounded } from "@material-ui/icons";
import ReportSubscriberWise from "./views/ReportStats/reportSubscriberWise";
import Release from "./views/Release/Release";
import SystemLog from "./views/SystemLogs/systemLog";
import ApiLogs from "./views/SystemLogs/ApiLog";

import CustomerList from "./views/SuperAdminComponents/CustomersList/Subscribers";
import Support from "./views/Support/Support";
import Dialling from "./views/Prospects/Dialling";
import SubscribersSuperadmin from "./views/SuperAdminComponents/Dashboard/SubscribersSuperadmin_New";
import SubscriberMain from "./views/Subscribers/SubscriberMain";
import InformationLink from "./views/InformationLink/InformationLink";
import RMAllocated from "./views/Subscribers/RMAllocated";


const dashboardRoutes = [
  
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    hide: false,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },
  {
    path: "/configuration",
    name: "Configuration",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: ConfigurationPage,
    layout: "/admin",
    hide: true,
    role: ['agent', 'owner']
  },
  {
    path: "/subscribers",
    name: "Subscribers",
    rtlName: "ملف تعريفي للمستخدم",
    icon: GroupIcon,
    component: SubscriberMain,
    layout: "/admin",
    hide: false,
    is_superuser: true,
    role: [ 'owner']
  },
  {
    path: "/subscriberssuperadmin",
    name: "My Subscribers",
    rtlName: "ملف تعريفي للمستخدم",
    icon: GroupIcon,
    component: SubscribersSuperadmin,
    layout: "/admin",
    hide: false,
    is_superuser: true,
    role: [ 'agent','manager']
  },
  {
    path: "/dialling",
    name: "Dialling",
    rtlName: "ملف تعريفي للمستخدم",
    icon: GroupIcon,
    component: Dialling,
    layout: "/admin",
    hide: true,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },
  {
    path: "/myteam",
    name: "My Team",
    rtlName: "ملف تعريفي للمستخدم",
    icon: GroupIcon,
    component: MyTeam,
    layout: "/admin",
    hide: false,
    role: ['owner']
  },
  {
    path: "/user",
    name: "Profile",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
    hide: true
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    rtlName: "إخطارات",
    icon: "fa fa-bullhorn",
    component: CampaignsPage,
    layout: "/admin",
    hide: false,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },
  // {
  //   path: "/Activity",
  //   name: "Activity",
  //   rtlName: "قائمة الجدول",
  //   icon: "fa fa-history",
  //   component: Activity,
  //   layout: "/admin",
  //   hide: false,
  //   role: ['agent', 'owner', 'manager', 'field_exc']
  // },
  {
    path: "/CustActivity",
    name: "Activity",
    rtlName: "طباعة",
    icon: "fa fa-calendar-check-o",
    component: CustActivity,
    layout: "/admin",
    hide: false,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },
  {
    path: "/Workload",
    name: "Report",
    rtlName: "قائمة الجدول",
    icon: "fa fa-history",
    component: Workload,
    layout: "/admin",
    hide: false,
    role: ['owner', 'manager']
  },
  // {
  //   path: "/AgentTracker",
  //   name: "Agent Tracker",
  //   rtlName: "قائمة الجدول",
  //   icon: "fa fa-history",
  //   component: AgentTracker,
  //   layout: "/admin",
  //   hide: false,
  //   role: ['owner', 'manager']
  // },
  // {
  //   path: "/CallTracker",
  //   name: "Call Tracker",
  //   rtlName: "قائمة الجدول",
  //   icon: "fa fa-history",
  //   component: CallTracker,
  //   layout: "/admin",
  //   hide: false,
  //   role: ['owner', 'manager']
  // },
  {
    path: "/Schedule",
    name: "Schedule",
    rtlName: "طباعة",
    icon: "fa fa-calendar-check-o",
    component: Schedule,
    layout: "/admin",
    hide: false,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },
  {
    path: "/ListProspects",
    name: "Prospects",
    rtlName: "الرموز",
    icon: "fa fa-child",
    component: ListProspects,
    layout: "/admin",
    hide: false,
    role: ['agent', 'owner', 'field_exc']
  },
  {
    path: "/FacebookPage",
    name: "Facebook Integration",
    rtlName: "الرموز",
    icon: "fa fa-child",
    component: FacebookPage,
    layout: "/admin",
    hide: true,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },

  {
    path: "/FacebookLeads/:id",
    name: "Facebook Leads",
    rtlName: "الرموز",
    icon: "fa fa-child",
    component: FacebookLeads,
    layout: "/admin",
    hide: true,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },




  {
    path: "/ProspectHistory",
    name: "Prospects Logs",
    rtlName: "الرموز",
    icon: "fa fa-users",
    component: ProspectHistory,
    layout: "/admin",
    hide: true,
    role: ['owner', 'manager']
  },

  {
    path: "/RMAllocated",
    name: "Relation Manager Allocation",
    rtlName: "الرموز",
    icon: "fa fa-users",
    component: RMAllocated,
    layout: "/admin",
    hide: true,
    role: ['owner', 'manager']
  },
  {
    path: "/forms",
    name: "Forms",
    rtlName: "خرائط",
    icon: "fa fa-wpforms",
    component: Forms,
    layout: "/admin",
    hide: false,
    role: ['owner']
  },
  {
    path: "/Files",
    name: "System Logs",
    rtlName: "الرموز",
    icon: "fa fa-file-text",
    component:  SystemLog,
    layout: "/admin",
    hide: false,
    role: [ 'owner', 'manager']
  },
  {
    
    path: "/APiLogs",
    name: "API Logs",
    rtlName: "الرموز",
    icon: "fa fa-file-text",
    component:  ApiLogs,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/ReportStats",
    name: "Report Stats",
    rtlName: "الرموز",
    icon: "fa fa-file-text",
    component: ReportStats,
    layout: "/admin",
    hide: TrendingUpRounded,
    role: [ 'owner', 'manager']
  },
  {
    path: "/ReportDetails/:id",
    name: "Report Details",
    rtlName: "الرموز",
    icon: "fa fa-file-text",
    component: ReportDetails,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/ReportSubscriberWise/:id",
    name: "Report Details",
    rtlName: "الرموز",
    icon: "fa fa-file-text",
    component: ReportSubscriberWise,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  // {
  //   path: "/ProspectHistory",
  //   name: "Prospects Logs",
  //   rtlName: "الرموز",
  //   icon: "fa fa-file-text",
  //   component: ProspectHistory,
  //   layout: "/admin",
  //   hide: false,
  //   role: [ 'owner', 'manager']
  // },





  {
    path: "/Integrations",
    name: "Integrations",
    rtlName: "الرموز",
    icon: "fa fa-wrench",
    component: Integrations,
    layout: "/admin",
    hide: false,
    role: [ 'owner']
  },
  {
    path: "/CallingSetupList",
    name: "CallingSetupList",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: CallingSetupList,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/CallingSetup/:id",
    name: "CallingSetup",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: CallingSetup,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/CallingSetup",
    name: "CallingSetup",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: CallingSetup,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/Templates",
    name: "Templates",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: Templates,
    layout: "/admin",
    hide: false,
    role: ['owner']
  },
  {
    path: "/EmailTemplate/:id",
    name: "EmailTemplate",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: EmailTemplate,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/EmailTemplate",
    name: "EmailTemplate",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: EmailTemplate,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/SMSTemplate/:id",
    name: "SMSTemplate",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: SMSTemplate,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/SMSTemplate",
    name: "SMSTemplate",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: SMSTemplate,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/WhatsAppTemplate/:id",
    name: "WhatsAppTemplate",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: WhatsAppTemplate,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/WhatsAppTemplate",
    name: "WhatsAppTemplate",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: WhatsAppTemplate,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/release",
    name: "release",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: Release,
    layout: "/admin",
    is_superuser: true,
    hide:true,
    role: []
  },
  {
    path: "/editrelease/:id",
    name: "edit release",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: Release,
    layout: "/admin",
    is_superuser: true,
    hide:true,
    role: []
  },
  {
    path: "/support",
    name: "Support",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: Support,
    layout: "/admin",
    is_superuser: true,
    hide:true,
    role: []
  },
  {
    path: "/info_link",
    name: "InformationLink",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: InformationLink,
    layout: "/admin",
    is_superuser: true,
    hide:true,
    role: []
  },
  {
    path: "/editsupport/:id",
    name: "edit Support",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: Support,
    layout: "/admin",
    is_superuser: true,
    hide:true,
    role: []
  },
  {
    path: "/login-page/Logout",
    name: "Logout",
    rtlName: "خرائط",
    icon: PowerSettingsNewOutlinedIcon,
    component: LoginPage,    
    layout: "/admin",
    hide: false,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },
 
  {
    path: "/addcampaigns",
    name: "Add Campaigns",
    rtlName: "إخطارات",
    icon: Notifications,
    component: ManageCampaigns,
    
    layout: "/admin",
    hide: true,
    role: ['owner', 'manager']
  },
  {
    path: "/CampainMoreInfo/:cid",
    name: "MoreInfo",
    rtlName: "إخطارات",
    icon: Notifications,
    component: CampainMoreInfo,
    layout: "/admin",
    hide: true,
    role: ['owner', 'manager']
  },
  {
    path: "/editcampaigns/:id",
    name: "Edit Campaigns",
    rtlName: "إخطارات",
    icon: Notifications,
    component: ManageCampaigns,
    layout: "/admin",
    hide: true,
    role: ['owner', 'manager']
  },
  {
    path: "/AddProspect/:cid",
    name: "Add Prospect",
    rtlName: "إخطارات",
    icon: Notifications,
    component: AddProspect,
    layout: "/admin",
    hide: true,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },
  {
    path: "/ViewProspect/:pid/:type",
    name: "View Prospect",
    rtlName: "إخطارات",
    icon: Notifications,
    component: ViewProspect,
    layout: "/admin",
    hide: true,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },
  {
    path: "/ViewProspect/:pid",
    name: "View Prospect",
    rtlName: "إخطارات",
    icon: Notifications,
    component: ViewProspect,
    layout: "/admin",
    hide: true,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },
  {
    path: "/CRMView/:admin",
    name: "CRM View",
    rtlName: "إخطارات",
    icon: Notifications,
    component: CRMView,
    layout: "/crm",
    hide: true,
    role: ['agent', 'owner', 'manager', 'field_exc']
  },
  
  {
    path: "/notifications",
    name: "Reports",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
    hide: false
  },
  {
    path: "/addnotification",
    name: "Add Notification",
    rtlName: "إخطارات",
    icon: Notifications,
    component: AddNotification,
    layout: "/admin",
    hide: false
  },


  {
    path: "/login-page",
    name: "Login Page",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Login,
    component: LoginPage,
    layout: "/auth",
    hide: true
  },
  {
    path: "/register-page",
    name: "Register Page",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Register,
    component: RegisterPage,
    layout: "/auth",
    hide: true
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    rtlName: "پشتیبانی از راست به چپ",
    icon: ForgetPassword,
    component: ForgetPassword,
    layout: "/auth",
    hide: true
  },
  {
    path: "/create-password",
    name: "Create Password",
    rtlName: "پشتیبانی از راست به چپ",
    icon: CreatePassword,
    component: CreatePassword,
    layout: "/auth",
    hide: true
  },
  {
    path: "/ChangePassword",
    name: "ChangePassword",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Person,
    component: ChangePassword,
    layout: "/admin",
    
  },
  {
    path: "/SignUpSuccess/:type",
    name: "SignUp Success",
    rtlName: "پشتیبانی از راست به چپ",
    icon: SignUpSuccess,
    component: SignUpSuccess,
    layout: "/auth",
    hide: true
  },
  {
    path: "/webhook",
    name: "webhook",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Person,
    component: WebHooks,
    layout: "/admin",
    hide: true,
    role: ['owner', 'manager']
  },
 
  {
    path: "/SMSSetupList",
    name: "SMSSetupList",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: SMSSetupList,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/SMSSetup/:id",
    name: "SMSSetup",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: SMSSetup,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/SMSSetup",
    name: "SMSSetup",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: SMSSetup,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },

  {
    path: "/WhatsAppSetupList",
    name: "WhatsAppSetupList",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: WhatsAppSetupList,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/WhatsAppSetup/:id",
    name: "WhatsAppSetup",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: WhatsAppSetup,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },
  {
    path: "/WhatsAppSetup",
    name: "WhatsAppSetup",
    rtlName: "الرموز",
    icon: "fa fa-wpforms",
    component: WhatsAppSetup,
    layout: "/admin",
    hide: true,
    role: [ 'owner', 'manager']
  },

  
];

export default dashboardRoutes;
