import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

const style = {
    grid: {
        padding: "0 5px !important",
    }
};

function WhatsApp({ classes, PhoneCode, Phone, ...rest }) {
    // Null checks for PhoneCode and Phone
    const formattedPhoneCode = PhoneCode === 91 ? "91" : "";
    const formattedPhone = Phone ? Phone.toString().replace("+", "") : "";

    // Generate WhatsApp link with null checks
    const whatsappLink = `https://wa.me/${formattedPhoneCode}${formattedPhone}`;
    /* WhatsApp button */
    return (
        <a
            href={whatsappLink}
            target="_blank"
            className="whatsapp"
            rel="noopener noreferrer" // To ensure security for external links
        >
            <i className="fa fa-whatsapp" />
        </a>

    );
}

export default withStyles(style)(WhatsApp);