import React from "react";
import {
  API_GET,
  API_POST,
//} from "../../services/api.service";
} from "../../../services/api.service";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Table from "components/Table/Table.jsx";
import Highcharts from 'highcharts';
import PieChart from "highcharts-react-official";
import SubscriberMember from "./SubscriberMember";

const optionsdef = {
  chart: {
    type: "pie"
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.y}'
      }
    }
  },
  title: { text: 'Paid Active' },
  series: [{
    name: 'Data',
    colorByPoint: true,
    data: []
  }]
};
const optionsdefnon = {
  chart: {
    type: "pie"
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.y}'
      }
    }
  },
  title: { text: 'Paid Non-Active' },
  series: [{
    name: 'Data',
    colorByPoint: true,
    data: []
  }]
};

const optionsdefexp = {
  chart: {
    type: "pie"
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.y}'
      }
    }
  },
  title: { text: 'Paid Expiry' },
  series: [{
    name: 'Data',
    colorByPoint: true,
    data: []
  }]
};

const optionsdemo = {
  chart: {
    type: "pie"
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.name}</b>: {point.y}'
      }
    }
  },
  title: { text: 'Demo account' },
  series: [{
    name: 'Data',
    colorByPoint: true,
    data: []
  }]
};


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class SubscribersSuperadmin_New extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      user: null,
      userInfo: null,
      allocatedUsersObj: [],
      allocatedUsers: [],
      AllActiveUsers: null,
      notify: {
        open: false,
        message: "",
        color: "success",
      },
      openchangemanager: false,
      openchangerole: false,
      selectedMember: null,
      adminTodayData: null,
    };
  

    this.userRoles = ["manager", "agent", "field_exc"];

  }

  async componentDidMount() {
    let getSessionRequest;
    getSessionRequest = await API_GET(`get-session`);
    //Page Authorization
    if (getSessionRequest && getSessionRequest.success) {
      //console.log("ddddddddddddddd",getSessionRequest.userInfo)
      if (getSessionRequest.userInfo.admin == 'mark@gmail.com' && getSessionRequest.userInfo.role != 'owner') {
        await this.getRMAllocatedUsers(getSessionRequest.userInfo.id);
      }
      this.setState({ user: getSessionRequest.userInfo });
      //await this.loadUserInfo(getSessionRequest.userInfo.id);
      await this.GetAllUsers(getSessionRequest.userInfo.username);
      this.loadAdmin();
      this.getChart();
    }
  }

  async getRMAllocatedUsers(userid) {
    let userInfo = await API_GET(`register/allocatedUsers?id=${userid}`);
    if (userInfo.success) {
      if (!userInfo.data.length) return true;
      this.setState({ allocatedUsersObj: userInfo.data, allocatedUsers: userInfo.data.map(v => v.email) });
    } else {
      this.setState({ allocatedUsersobj: [] });
    }
  }


  async getChart() {


    try {




      let getRecords = await API_POST("report/customerReport", {
        cons: {
          createdOn: {
            $gte: new Date(new Date().setHours(0, 0, 0, 0)),
            $lt: new Date(new Date().setHours(23, 59, 59, 0)),
          }
        },
        root: 'CallHistory',
        filter: { user: [...this.state.allocatedUsers], status: ['Paid account', 'Paid expired', 'Demo account', 'change status'] }
      });

      let seriesdataformat = [
        { name: 'Happy Customers', y: 0 },
        { name: 'Recording Customer', y: 0 },
        { name: 'Irregular', y: 0 },
        { name: 'Less Usag', y: 0 },
        { name: '30 days observation', y: 0 },
        { name: 'Lets Focus', y: 0 },
        { name: 'total', y: 0 }
      ];

      let paidActiveSeries = {
        name: 'Paid Active',
        colorByPoint: true,
        data: [
          { name: 'Happy Customers', y: 0 },
          { name: 'Recording Customer', y: 0 },
          { name: 'Irregular', y: 0 },
          { name: 'Less Usag', y: 0 },
          { name: '30 days observation', y: 0 },
          { name: 'Lets Focus', y: 0 },
          { name: 'total', y: 0 }
        ]
      };
      let paidNonActiveSeries = {
        name: 'Paid Non-Active',
        colorByPoint: true,
        data: [
          { name: 'Happy Customers', y: 0 },
          { name: 'Recording Customer', y: 0 },
          { name: 'Irregular', y: 0 },
          { name: 'Less Usag', y: 0 },
          { name: '30 days observation', y: 0 },
          { name: 'Lets Focus', y: 0 },
          { name: 'total', y: 0 }
        ]
      };
      let paidExpiredSeries = {
        name: 'Paid Expired',
        colorByPoint: true,
        data: [
          { name: 'Happy Customers', y: 0 },
          { name: 'Recording Customer', y: 0 },
          { name: 'Irregular', y: 0 },
          { name: 'Less Usag', y: 0 },
          { name: '30 days observation', y: 0 },
          { name: 'Lets Focus', y: 0 },
          { name: 'total', y: 0 }
        ]
      };

      let demoseries = {
        name: 'Demo Account',
        colorByPoint: true,
        data: [
          { name: 'Happy Customers', y: 0 },
          { name: 'Recording Customer', y: 0 },
          { name: 'Irregular', y: 0 },
          { name: 'Less Usag', y: 0 },
          { name: '30 days observation', y: 0 },
          { name: 'Lets Focus', y: 0 },
          { name: 'Followups', y: 0 },
          { name: 'Not Followed', y: 0 },
          { name: 'Not Connected', y: 0 },
          { name: 'total', y: 0 },
        ]
      };
      let format = {
        'Happy Customers': 0,
        'Recording Customer': 0,
        'Irregular': 0,
        'Less Usage': 0,
        '30 days observation': 0,
        'Lets Focus': 0,
        'Followups': 0,
        'Not Followed': 0,
        'Not Connected': 0,
        'total': 0
      }

      let dataChartObj = {
        'Paid Active': Object.create(format),
        'Paid Non-active': Object.create(format),
        'Paid expired': Object.create(format),
        'Demo account': Object.create(format)
      }

      if (getRecords.data) {

        // Paid Active 
        getRecords.data.map((v) => {

          let selectType = null;
          if (v.status == 'Paid expired') {
            selectType = 'Paid expired';
          } else if (v.status == 'Demo account' || v.status == 'change status') {
            selectType = 'Demo account';
          } else {
            if (v.todayActive) {
              selectType = 'Paid Active';
            } else {
              selectType = 'Paid Non-active';
            }
          }

          if (selectType && v.category) {
            dataChartObj[selectType][v.category]++;

          }
          dataChartObj[selectType]['total']++;
          return true;
        })



        paidActiveSeries.data = paidActiveSeries.data.map((v) => { v.y = dataChartObj['Paid Active'][v.name]; return v; })
        paidNonActiveSeries.data = paidNonActiveSeries.data.map((v) => { v.y = dataChartObj['Paid Non-active'][v.name]; return v; })
        paidExpiredSeries.data = paidExpiredSeries.data.map((v) => { v.y = dataChartObj['Paid expired'][v.name]; return v; })
        demoseries.data = demoseries.data.map((v) => { v.y = dataChartObj['Demo account'][v.name]; return v; })

        //console.log("newwwwwwwwww", demoseries.data, dataChartObj)

        let paidac = optionsdef;
        paidac.series = [paidActiveSeries]
        paidac.title.text = 'Paid Active - ' + paidActiveSeries.data.filter((v) => v.name == 'total')[0].y;
        let paidnonac = optionsdefnon;
        paidnonac.series = [paidNonActiveSeries];
        paidnonac.title.text = 'Paid Non-Active - ' + paidNonActiveSeries.data.filter((v) => v.name == 'total')[0].y;
        let exp = optionsdefexp;
        exp.series = [paidExpiredSeries];
        exp.title.text = 'Paid Expired - ' + paidExpiredSeries.data.filter((v) => v.name == 'total')[0].y;

        optionsdemo.series = [demoseries];
        optionsdemo.title.text = 'Demo Account - ' + demoseries.data.filter((v) => v.name == 'total')[0].y;

        this.setState({ graphExpiredop: exp, graphNonActiveop: paidnonac, graphActiveop: paidac, graphDemo: optionsdemo })

        //console.log("newwwwwwwwww",dataChartObj,paidac,exp)

      }

    } catch (e) {
      console.log("rrrrrrrrrr", e)
    }



  }


  async loadAdmin() {

    try {

      let createdDate = {
        $gte: new Date(new Date().setHours(0, 0, 0, 0)),
        $lt: new Date(new Date().setHours(23, 59, 59, 0)),
      }
      let q = { createdOn: createdDate, user: [...this.state.allocatedUsers] };

      let requestData = await API_POST("report/adminSpecificReport", {
        root: 'userStats',
        con: q,
      });

      this.setState({ adminTodayData: requestData.data })
      //setAdminTodayData(requestData.data);

    } catch (e) {
      console.log("loadddddd", e)
    }


  }



  async GetAllUsers(username) {
    let userInfo = await API_GET(`register/GetAllUsers?username=${username}`);
    if (userInfo.success) {
      if (this.state.allocatedUsers.length) {
        userInfo.data = userInfo.data.filter((v) => { return this.state.allocatedUsers.includes(v.admin) })
      }
      //alert(userInfo.data.length)
      this.setState({ AllActiveUsers: userInfo.data });
    } else {
      this.setState({ AllActiveUsers: null });
    }
  }





  render() {
    const { classes, name, email } = this.props;
    const { errors, user, userInfo, AllActiveUsers } = this.state;

    // if (!userInfo) {
    //   return <Loader />;
    // }


    let ActiveUsers = null;
    if (AllActiveUsers) {
      var ActiveUserstableData = [];
      for (let index = 0; index < AllActiveUsers.length; index++) {
        const element = AllActiveUsers[index];

        ActiveUserstableData.push([
          element.admin,
          element.username,
          element.date_joined
            ? window.moment(element.date_joined).fromNow()
            : "NA",
          element.last_login
            ? window.moment(element.last_login).fromNow()
            : "NA",
          element.expiry ? window.moment(element.expiry).fromNow() : "NA",
          element.password,
          element.is_active && element.is_active == 1 ? (
            <Button
              color="danger"
              onClick={(e) => this.changeUserType(e, element, 0)}
            >
              {" "}
              DeActive
            </Button>
          ) : (
            <Button
              color="success"
              onClick={(e) => this.changeUserType(e, element, 1)}
            >
              Active
            </Button>
          ),
        ]);
      }
      ActiveUsers = (
        <Table
          tableHeaderColor="primary"
          tableHead={[
            "Owner",
            "User",
            "Created On",
            "Last Login",
            "Expired On",
            "Password",
          ]}
          tableData={ActiveUserstableData}
        />
      );
    }

    return (
      <div>
        <Snackbar
          place="tr"
          color={this.state.notify.color}
          message={this.state.notify.message}
          open={this.state.notify.open}
          closeNotification={() =>
            this.setState({
              notify: {
                open: false,
              },
            })
          }
          close
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <h2>Admin Live Data</h2>
                <GridContainer>
                  <Box label="Total Signup" value={this.state.adminTodayData && this.state.adminTodayData.totalSubscriber} />
                  <Box label="Total Agent" value={this.state.adminTodayData && this.state.adminTodayData.totalAgent} />
                  <Box label="Demo Account" value={this.state.adminTodayData && this.state.adminTodayData.demoSubscriber} />
                  <Box label="Internal Account" value={this.state.adminTodayData && this.state.adminTodayData.internalAccount} />

                  <Box label="Duplicate  Account" value={this.state.adminTodayData && this.state.adminTodayData.duplicate} />


                  <Box label="Paid Expired Account" value={this.state.adminTodayData && this.state.adminTodayData.paidExpired} />

                  <Box label="Paid  Account" value={this.state.adminTodayData && this.state.adminTodayData.paidSubscriber} />

                  <Box label="Today  Signup" value={this.state.adminTodayData && this.state.adminTodayData.newSignup} />
                  <Box label="Agent Created" value={this.state.adminTodayData && this.state.adminTodayData.agentCreation} />
                  <Box label="Active Subscriber" value={this.state.adminTodayData && this.state.adminTodayData.activeSubscriber} />
                  <Box label="Non-Active Subscriber" value={this.state.adminTodayData && this.state.adminTodayData.nonactiveSubscriber} />
                  <Box label="Active Agents" value={this.state.adminTodayData && this.state.adminTodayData.lastActivityCount} />



                </GridContainer>
              </CardBody>
            </Card>


            <Card chart>
              <CardHeader color="primary">

                <GridContainer>


                  <GridItem xs={12} sm={12} md={6}>
                    {
                      this.state.graphActiveop && <PieChart highcharts={Highcharts} options={this.state.graphActiveop} />
                    }
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {
                      this.state.graphNonActiveop && <PieChart highcharts={Highcharts} options={this.state.graphNonActiveop} />
                    }

                  </GridItem>
                  <br />
                  <GridItem xs={12} sm={12} md={6}>
                    {
                      this.state.graphExpiredop && <PieChart highcharts={Highcharts} options={this.state.graphExpiredop} />
                    }
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    {
                      this.state.graphDemo && <PieChart highcharts={Highcharts} options={this.state.graphDemo} />
                    }
                  </GridItem>


                </GridContainer>
              </CardHeader>
              <CardBody />
              <CardFooter chart />
            </Card>

          </GridItem>
        </GridContainer>

        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <SubscriberMember
              //allocatedUsers={this.state.allocatedUsers}
              //showCustomerLiveData={true}
              ActiveUsers={AllActiveUsers}
              //changeUserType={this.changeUserType.bind(this)}
              //changeExpiry={this.changeExpiry.bind(this)}
              //moveRecordsToArchive={this.moveRecordsToArchive.bind(this)}
              // changeCount={this.changeCount.bind(this)}
              // changeUserStatus={this.changeUserStatus.bind(this)}
               //changeUserCategory={this.changeUserCategory.bind(this)}
              // changeDetails={this.changeDetails.bind(this)}
              user={this.state.user}
            />
          
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function Box(props) {

  return (<>

    <GridItem xs={12} sm={2} md={2}>
      <Card>
        <CardHeader>
          <b><h6>{props.label}</h6> </b>
          {<h4>
            {props && props.value}
          </h4>}
        </CardHeader>
      </Card>
    </GridItem>

  </>)


}

export default withStyles(styles)(SubscribersSuperadmin_New);
