import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import _, { forEach, values } from "underscore";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import {
  API_GET,
  API_POST,
  userRoles,
  InviteeStatus,
} from "../../../services/api.service";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Tab,
} from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import { Link } from "react-router-dom";


const categoryStatusOption = [
  { value: 'Not Followed', name: 'Not Followed' },
  { value: 'Happy Customers', name: 'Happy Customers' },
  { value: 'Recording Customer', name: 'Recording Customer' },
  { value: 'Irregular', name: 'Irregular' },
  { value: 'Less Usage', name: 'Less Usage' },
  { value: '30 days observation', name: '30 days observation' },
  { value: 'Lets Focus', name: 'Lets Focus' },
  { value: 'Followups', name: 'Followups' },
  { value: 'Not Connected', name: 'Not Connected' }
];


function SubscriberMember(props) {
  const { showMember, allocatedUsers = [], changeAllocated = false, showCustomerLiveData, history, user, changeUserType, changeExpiry, changeCount, changeDetails, moveRecordsToArchive } = props;
  const [data, setData] = useState([]);
  const [categoryStatus, setCategoryStatus] = useState(categoryStatusOption);
  const [filterAcount, setFilterAccount] = useState('Paid account');
  const [filterlastActive, setFilterLastActive] = useState(false);
  const [filterlastNonActive, setFilterNonLastActive] = useState(false);
  const [customerRecords, setCustomerRecords] = useState(null);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [endDate, setEndDate] = useState(new Date().setHours(23, 59, 59, 0));
  const [openChangeAllocation, setOpenChangeAllocation] = useState(false);
  const [selectedMember, setSelectedMember] = useState({});
  const [openChangeCategory, setOpenChangeCategory] = useState(false);
  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [userStatus, setUserStatus] = useState([]);
  const [userStatusOptions, setUserStatusOptions] = useState([]);
  const [notify, setNotify] = useState({
    open: false,
    message: "",
    color: "success",
  });

  useEffect(() => {

    findActiveSubscriber()

  }, [filterAcount, filterlastActive, filterlastNonActive]);
  
  const customerColumn = [
    {
      name: "Expire On",
      selector: row => row.expireon,
      width: "100px",
      cell: (row) => (
        <span>
          {row.expireon ? window.moment(row.expireon).format("DD-MMM-YYYY") : 'N/A'}
        </span>
      ),
    },
    {
      name: "Category",
      selector: row => row.category,
      width: "100px",
      cell: (row) =>
        (filterAcount === "Paid account" ||
          filterAcount === "Paid expired" ||
          filterAcount === "Demo account") ? (
          <a
            onClick={(e) => {
              setOpenChangeCategory(true);
              setSelectedMember(row);
            }}
            style={{ cursor: "pointer" }}
          >
            {row && row.category ? row.category : "Change Category"}
          </a>
        ) : null,
    },
    {
      name: "Company",
      selector: row => row.company,
      cell: (row) => (
        <span>
          <a onClick={() => handleURL(row)} style={{ cursor: "pointer" }}>
            {row.company || 'N/A'}
          </a>
        </span>
      ),
      width: "250px",
    },
    {
      name: "Activity Count",
      selector: row => row.todayActivityCount,
      width: "80px",
    },
    {
      name: "Today Active",
      selector: row => row.todayActive,
      width: "70px",
      cell: (row) => (
        <span>
          {row.todayActive ? 'Active' : 'Not Active'}
        </span>
      ),
    },
    {
      name: "Name",
      selector: row => row.name,
      width: "200px",
      cell: (row) => row.name || 'N/A',
    },
    {
      name: "Password",
      selector: row => row.password,
      width: "auto",
    },
    {
      name: "Phone",
      selector: row => row.phone,
      width: "150px",
      cell: (row) => row.phone || 'N/A',
    },
    {
      name: "Disposition",
      selector: row => row.disposition,
      width: "170px",
      cell: (row) => row.disposition || 'N/A',
    },
    {
      name: "Last Update",
      selector: row => row.lastUpdate,
      width: "150px",
      cell: (row) => (
        <>{row.lastUpdate && window.moment(row.lastUpdate).format("DD-MMM-YYYY")}</>
      ),
    },
    {
      name: "Max User",
      selector: row => row.max_users,
      width: "70px",
      cell: (row) => row.max_users || 'N/A',
    },
    {
      name: "Active User",
      selector: row => row.activeCount,
      width: "80px",
      cell: (row) => row.activeCount || 'N/A',
    },
    {
      name: "Activity Count",
      selector: row => row.todayActivityCount,
      width: "80px",
      cell: (row) => row.todayActivityCount || 'N/A',
    },
    {
      name: "Refer By",
      selector: row => row.refer_by,
      width: "200px",
      cell: (row) => row.refer_by || 'N/A',
    },
    {
      name: "Email",
      selector: row => row.email,
      width: "270px",
      cell: (row) => row.email || 'N/A',
    },
    {
      name: "Owner Id",
      selector: row => row.id,
      width: "100px",
      cell: (row) => row.id || 'N/A',
    },
   
    {
      name: "Status",
      selector: row => row.status,
      cell: (row) =>
        row.role === "owner" ? (
          <a
            onClick={(e) => {
              setOpenChangeStatus(true);
              setSelectedMember(row);
            }}
            style={{ cursor: "pointer" }}
          >
            {row.status ? row.status : "Change Status"}
          </a>
        ) : null,
    },
  ];

const loadCampaigns = async () => {
    const { user } = props;

    let registerRequest;
    let IsActive = true;
    try {
      registerRequest = await API_POST("action/getdata", {
        root: "campaigns",
        con: { admin: "mark@gmail.com", IsActive },
        //cols: {}
      });



      if (registerRequest && registerRequest.status == 200) {
        setUserStatus(registerRequest.data);
        let statusOptions = [];
        for (let i = 0; i < registerRequest.data.length; i++) {
          let element = registerRequest.data[i];
          statusOptions.push({
            key: element.name,
            value: element.name
          })

        }
        setUserStatusOptions(statusOptions);

      }

    } catch (error) {
      console.log(error);
    }
  };
  const handleURL = async (data) => {

    try {

      window.location.href = '/admin/viewprospect/' + data.prospectId;
      //console.log(registerRequest && registerRequest.data);


    } catch (e) {

      console.log(e)
    }

  }

  const changeUserCategory = async (e, selectedMember, newVal) => {
    debugger;
    let member = selectedMember;

    try {
      let registerRequest = await API_POST("register/updateCategory", {
        id: member.id,
        category: newVal,
      });
      console.log(registerRequest);
      if (registerRequest && registerRequest.data.update) {
        this.GetAllUsers(this.state.user.username);
        this.setState({
          notify: {
            open: true,
            message: "Status changed Successfully.",
            color: "success",
          },
        });

      }
    } catch ({ response }) {
      //registerRequest = response;
    }
    return true;
  }

  const GetAllUsers = async (username) => {
    let userInfo = await API_GET(`register/GetAllUsers?username=${username}`);
    if (userInfo.success) {
      if(this.state.allocatedUsers.length){
        userInfo.data = userInfo.data.filter((v)=>{return this.state.allocatedUsers.includes(v.admin) })
      }
      //alert(userInfo.data.length)
      this.setState({ AllActiveUsers: userInfo.data });
    } else {
      this.setState({ AllActiveUsers: null });
    }
  }

  const changeUserStatus = async (e, selectedMember, newVal) =>{
    
    let member = selectedMember;
    if (!window.confirm("Are you sure! you wanna change status to " + member.status)) {
      return false;
    }
    try {
      let registerRequest = await API_POST("register/changeUserStatus", {
        id: member.id,
        status: newVal,
      });
      console.log(registerRequest);
      if (registerRequest && registerRequest.data.update) {
        //this.GetAllUsers(this.state.user.username);
        this.setState({
          notify: {
            open: true,
            message: "Status changed Successfully.",
            color: "success",
          },
        });
        
      }
    } catch ({ response }) {
      //registerRequest = response;
    }
    return true;
  }


  const findActiveSubscriber = async () => {

    if (!props.user) {
      return true;
    }
    // if (props.user && props.user.role != 'owner' && allocatedUsers.length == 0) {
    //   return true;
    // }


    let getRecords = await API_POST("report/customerReport", {
      cons: {
        createdOn: {
          $gte: new Date(startDate),
          $lt: new Date(endDate),
        }
      },
      root: 'CallHistory',
      filter: { status: filterAcount }
    });


    if (filterlastActive) {

      getRecords.data = getRecords.data.filter((v) => {
        if (v.todayActive) {
          return true;
        }
        return false;
      })

    }

    if (filterlastNonActive) {
      getRecords.data = getRecords.data.filter((v) => {
        if (!v.todayActive) {
          return true;
        }
        return false;
      })
    }

    setCustomerRecords(getRecords.data);
    // console.log(getRecords.data)



  }

  useEffect(() => {
    loadCampaigns();
    findActiveSubscriber();
  }, [filterAcount]);



  useEffect(() => {
    if (props.ActiveUsers && props.ActiveUsers.length > 0) {
      debugger;
      findActiveSubscriber()
      setData(props.ActiveUsers);
      setCustomerRecords(props.ActiveUsers)
    }
  }, [props.ActiveUsers]);

  const onChangeUserCategory = async (e, element) => {

    setOpenChangeCategory(false);
    try {
      if (await changeUserCategory(e, selectedMember, element.name)) {
      }
    } catch (e) {
      console.log(e)
    }

  }
  const onChangeUserStatus = async (e, element) => {

    setOpenChangeStatus(false);
    try {
      if (await changeUserStatus(e, selectedMember, element.name)) {
        //selectedMember.status = element;
      }
    } catch (e) {
      console.log(e)
    }

  }


  return (
    <>
     <Snackbar
        place="tr"
        color={notify.color}
        message={notify.message}
        open={notify.open}
        closeNotification={() =>
          setNotify(...{
            open: false
          })
        }
        close
      />
      <Dialog
        onClose={() => setOpenChangeCategory(false)}
        aria-labelledby="simple-dialog-title"
        open={openChangeCategory}
      >
        <DialogTitle id="simple-dialog-title">Change Category</DialogTitle>
        <List>
          {categoryStatus.map((item) => (
            <ListItem
              button
              onClick={(e) => onChangeUserCategory(e, item)}
              key={item}
            >
              {/* <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <i />
                </Avatar>
              </ListItemAvatar> */}
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Dialog>

      <Dialog
        onClose={() => setOpenChangeStatus(false)}
        aria-labelledby="simple-dialog-title"
        open={openChangeStatus}
      >
        <DialogTitle id="simple-dialog-title">Change User Status</DialogTitle>
        <List>
          {userStatus.map((item) => (
            <ListItem
              button
              onClick={(e) => onChangeUserStatus(e, item)}
              key={item}
            >
              {/* <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <i />
                </Avatar>
              </ListItemAvatar> */}
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Dialog>

      
      <Card>

        <CardHeader color="transparent">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h3>
                Customer Realtime Data
                <span style={{ float: "right", fontSize: "15px" }} />
              </h3>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>


              <Box sx={{ bgcolor: 'background.paper', width: '100%', typography: 'body1' }} style={{ "background": "rgb(38 80 119)", "width": "100%" }}>
                <TabContext value={filterAcount}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(e, n) => { setFilterAccount(n) }} aria-label="lab API tabs example" indicatorColor="secondary">
                      <Tab label="Paid account" value="Paid account" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                      <Tab label="Paid expired" value="Paid expired" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                      <Tab label="Demo account" value="Demo account" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                      <Tab label="Internal account" value="Internal account" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                      <Tab label="Duplicate" value="Duplicate" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                      <Tab label="ALL Account" value="All Account" style={{ textTransform: 'none', fontSize: '18px', color: 'white' }} />
                    </TabList>
                  </Box>
                </TabContext>
              </Box>

            </GridItem>

            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                id={"body_variable_value_"}
                labelText={"Active"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  type: "checkbox",
                  value: filterlastActive,
                  name: "last_activity",
                  onChange: (e) => {
                    setFilterLastActive(e.target.checked)
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <CustomInput
                id={"body_variable_value_"}
                labelText={"Non Active"}
                labelProps={{ shrink: true }}
                formControlProps={{
                  fullWidth: true,
                  multiline: false,
                }}
                inputProps={{
                  required: true,
                  type: "checkbox",
                  value: filterlastNonActive,
                  name: "last_activity",
                  onChange: (e) => {
                    setFilterNonLastActive(e.target.checked)
                  },
                }}
              />

            </GridItem>
          </GridContainer>
        </CardHeader>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>

            <DataTableExtensions
              columns={customerColumn}
              data={customerRecords || []}
              export={true}
              exportHeaders={true}
              print={false}
            >
              <DataTable
                columns={customerColumn}
                data={customerRecords || []}
                pagination
                striped
                highlightOnHover
                pointerOnHover
              />
            </DataTableExtensions>
          </GridItem>
        </GridContainer>






      </Card>




    </>
  );
}

export default SubscriberMember;
