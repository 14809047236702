// src/components/PivotControl.js
import React, { useEffect } from 'react';
import GenerateField from '../CustomSelect/GenerateField';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';

const PivotControl = ({ pivotKeys, setPivotKeys, columns }) => {
    const [allOptions, setAllOptions] = React.useState([]);
    if (!columns || !Array.isArray(columns)) {
        return null; // or return a fallback UI
    }

useEffect(() => {
    let _options = [];
    columns.forEach((element) => {
      _options.push({
        key: element.id,
        value: element.label,
      });
    });

    setAllOptions(_options);
}, [columns]);

    return (
        <FormControl fullWidth variant="outlined">
            <GenerateField
                    value={pivotKeys}
                    onChange={(e) => setPivotKeys(e.target.value)}
                    defaultValue={"Select"}
                    id={"pivotFields"}
                    name={"pivotFields"}
                    label={"Select Grouop Key(s)"}
                    type="checkbox_list"
                    keyValuePair={allOptions}
                  />
            {/* <InputLabel id="pivot-select-label">Select Pivot Key(s)</InputLabel>
            
            <Select
                labelId="pivot-select-label"
                multiple
                value={pivotKeys}
                onChange={(e) => setPivotKeys(e.target.value)}
                renderValue={(selected) => selected.join(', ')}
            >
                {columns.map((column) => (
                    column && column.id && (
                        <MenuItem key={column.id} value={column.id}>
                            <Checkbox checked={pivotKeys.indexOf(column.id) > -1} />
                            <ListItemText primary={column.label || column.id} />
                        </MenuItem>
                    )
                ))}
            </Select> */}
        </FormControl>
    );
};

export default PivotControl;