// src/components/ReportDashboard.js
import React, { useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import ReportFilters from './ReportFilters';
import ReportTable from './ReportTable';
import PivotTable from './PivotTable';
import PivotControl from './PivotControl';

const ReportDashboard = ({ reportData, loadReports, columns, defaultViewMode, defaultPivotKeys, enableMode }) => {
    const [pivotKeys, setPivotKeys] = useState([...defaultPivotKeys]); // Multi-selection
    const [viewMode, setViewMode] = useState(defaultViewMode); // 'reportTable' or 'pivotTable'

    const handleViewChange = (event) => {
        setViewMode(event.target.value);
    };

    return (
        <div>
            {/* Report Filters */}
            
            

            {/* View Mode Selector */}
            {enableMode &&
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="view-mode-select-label">Select View</InputLabel>
                <Select
                    labelId="view-mode-select-label"
                    value={viewMode}
                    onChange={handleViewChange}
                    label="Select View"
                >
                    <MenuItem value="reportTable">Report Table</MenuItem>
                    <MenuItem value="pivotTable">Pivot Table</MenuItem>
                </Select>
            </FormControl>}

            {/* Conditionally render ReportTable or PivotTable */}
            {viewMode === 'reportTable' && (
                <><ReportFilters onFilterChange={loadReports} />
                <ReportTable columns={columns} data={reportData} /></>
            )}

            {viewMode === 'pivotTable' && (
                <>
                    <PivotControl 
                        pivotKeys={pivotKeys} 
                        setPivotKeys={setPivotKeys} 
                        columns={columns} 
                    />
                    {pivotKeys.length > 0 && (
                        <PivotTable 
                            data={reportData} 
                            pivotKeys={pivotKeys} 
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ReportDashboard;