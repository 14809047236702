// src/components/PivotTable.js
import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Styles for the table
const useStyles = makeStyles((theme) => ({
    table: {
        borderCollapse: 'collapse', // Collapses borders for a cleaner look
    },
    tableHead: {
        backgroundColor: theme.palette.grey[200], // Light background for header
    },
    tableCell: {
        borderBottom: '1px solid #e0e0e0', // Light grey border for cells
        padding: '8px 12px', // Reduced padding for a narrower appearance
        fontSize: '0.875rem', // Smaller font size for a more compact look
    },
    tableHeader: {
        fontWeight: 'bold', // Bold font for header cells
    },
    tableRow: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover, // Subtle hover effect
        },
    },
    boldCell: {
        fontWeight: 'bold', // Bold font for every n-1 level
        color: theme.palette.primary.main, // Change this color as needed
    },
    normalCell: {
        color: theme.palette.text.primary, // Normal text color for other levels
    },
    level0Cell: {
        fontWeight: 'bold', // Bold for level 0
        color: theme.palette.secondary.main, // Different color for level 0
        paddingLeft: '40px', // Additional padding for level 0
    },
}));

/**
 * Recursively renders nested rows based on the grouping of pivot keys.
 * 
 * @param {Object} groupedData - The grouped data based on the pivot keys.
 * @param {Array} pivotKeys - The array of pivot keys to group by.
 * @param {number} level - The current nesting level (for recursive depth).
 * @returns {JSX.Element[]} An array of table rows representing the nested data.
 */
const renderNestedRows = (groupedData, pivotKeys, level = 0, classes) => {
    if (pivotKeys.length === 0) return null;

    return Object.entries(groupedData).map(([groupValue, subGroup]) => {
        const isLeafNode = !subGroup || typeof subGroup !== 'object' || Array.isArray(subGroup);

        // Determine padding based on level
        const paddingLeft = `${level * 20 + 10}px`; // Dynamic padding for deeper levels

        return (
            <React.Fragment key={groupValue}>
                <TableRow className={classes.tableRow}>
                    <TableCell
                        className={
                            level === 0 ? classes.level0Cell :
                            level % 2 === 0 ? classes.boldCell : 
                            classes.normalCell
                        }
                        style={{ paddingLeft }}
                    >
                        {groupValue}
                    </TableCell>
                    <TableCell className={classes.tableCell}>{isLeafNode ? subGroup.length : ''}</TableCell>
                    <TableCell className={classes.tableCell}>{isLeafNode ? subGroup.reduce((sum, item) => sum + (item.value || 0), 0).toFixed(2) : ''}</TableCell>
                </TableRow>
                {/* Recursively render nested levels */}
                {!isLeafNode && renderNestedRows(subGroup, pivotKeys.slice(1), level + 1, classes)}
            </React.Fragment>
        );
    });
};

const PivotTable = ({ data, pivotKeys }) => {
    const classes = useStyles();

    if (!data || !Array.isArray(data) || data.length === 0) {
        return <div>No data available</div>;
    }

    if (!pivotKeys || !Array.isArray(pivotKeys) || pivotKeys.length === 0) {
        return <div>Invalid pivot keys</div>;
    }

    // Group data based on pivot keys
    const groupDataByKeys = (data, keys) => {
        if (keys.length === 0) return data;

        const [key, ...restKeys] = keys;
        const grouped = {};

        data.forEach(row => {
            const groupValue = row[key] || 'Unknown';
            // Initialize the group if it doesn't exist
            if (!grouped[groupValue]) {
                grouped[groupValue] = [];
            }
            // Add the row to the group
            grouped[groupValue].push(row);
        });

        // Recursively group sub-groups
        for (const groupValue in grouped) {
            grouped[groupValue] = groupDataByKeys(grouped[groupValue], restKeys);
        }

        return grouped;
    };

    const groupedData = groupDataByKeys(data, pivotKeys);

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table}>
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell className={`${classes.tableCell} ${classes.tableHeader}`}>Category</TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHeader}`}>Count</TableCell>
                        <TableCell className={`${classes.tableCell} ${classes.tableHeader}`}>Total Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderNestedRows(groupedData, pivotKeys, 0, classes)}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PivotTable;