import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { searchVal } from "../../store/Actions/actions";
import { connect } from "react-redux";
import Clock from "../Clock/Clock";
import PushNotification from "./PushNotification";
import "./Styles/Navbar.css";
import { removeToken } from "../../services/Common";
import { API_POST } from "../../services/api.service";
import { AccountCircleOutlined, CloseRounded } from "@material-ui/icons";
import { Badge, Grid } from "@material-ui/core";
import socketIOClient from "socket.io-client";
import TimeLine from "./TimeLine";
const { REACT_SOCKET_SERVER_URL } = process.env;
//export const base_url = `//localhost:8001/`;

class HeaderLinks extends React.Component {
  state = {
    open: false,
    profilePopupOpen: false,
    notificationPopup: false,
    notifications: null,
    batteryCss: "fa fa-battery-0",
    time: "00:00:00",
    amPm: "am",
    SearchBox: "",
    isMobile: (window.innerWidth <= 760),
    newNotification: 0
  };

  userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));

  handleToggle = () => {
    this.setState((state) => ({ open: !state.open, profilePopupOpen: false }));
  };

  handleToggleProfile = () => {
    this.setState((state) => ({
      profilePopupOpen: !state.profilePopupOpen,
      open: false,
    }));
  };




  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false, profilePopupOpen: false });
  };



  logout = async () => {
    const { history } = this.props;
    removeToken();
    localStorage.clear();



    try {
      window.location.reload();
      window.logout.performClick();

    }
    catch (e) { }
  };

  async componentDidMount() {
    const { user } = this.props;
    let that = this;
    let isBatterySupported = "getBattery" in navigator;
    if (!isBatterySupported) {
      console.log("Battery not supported");
    } else {
      let batteryPromise = navigator.getBattery();
      batteryPromise.then(batteryCallback);

      function batteryCallback(batteryObject) {
        printBatteryStatus(batteryObject);

        batteryObject.addEventListener("chargingchange", function (event) {
          printBatteryStatus(batteryObject);
        });
        batteryObject.addEventListener("levelchange", function (event) {
          printBatteryStatus(batteryObject);
        });
      }
      function printBatteryStatus(batteryObject) {
        // console.log("IsCharging", batteryObject.charging);
        // console.log("Percentage", batteryObject.level);
        // console.log("charging Time", batteryObject.chargingTime);
        // console.log("DisCharging Time", batteryObject.dischargingTime);

        if (batteryObject.charging) {
          that.setState({ batteryCss: "fa fa-battery-4" });
        } else {
          if (batteryObject.level < 0.25) {
            that.setState({ batteryCss: "fa fa-battery-0" });
          } else if (batteryObject.level < 0.5) {
            that.setState({ batteryCss: "fa fa-battery-1" });
          } else if (batteryObject.level < 0.75) {
            that.setState({ batteryCss: "fa fa-battery-2" });
          } else if (batteryObject.level < 1.0) {
            that.setState({ batteryCss: "fa fa-battery-3" });
          }
        }
      }
    }


  }


  getTime() {
    const takeTwelve = (n) => (n > 12 ? n - 12 : n),
      addZero = (n) => (n < 10 ? "0" + n : n);

    setInterval(() => {
      let d, h, m, s, t, amPm;

      d = new Date();
      h = addZero(takeTwelve(d.getHours()));
      m = addZero(d.getMinutes());
      s = addZero(d.getSeconds());
      t = `${h}:${m}:${s}`;

      amPm = d.getHours() >= 12 ? "pm" : "am";

      this.setState({
        time: t,
        amPm: amPm,
      });
    }, 1000);
  }

  handleChange = (e) => {
    this.setState({ SearchBox: e.target.value });
  };

  getProspects = (e) => {
    if (e && e.key === "Enter") {
      this.getProspectDetails();
    }
  };
  getProspectDetails = (e) => {
    if (this.state.SearchBox) {
      this.props.setSearchValToRedux(this.state.SearchBox);
      this.hanldeSwitchPage();
    }
    // this.setState({SearchBox:""});
  };

  hanldeSwitchPage = () => {
    const { user } = this.props;
    const { history } = this.props;
    let query;
    query = {
      $and: [
        { admin: user && user.admin },
        {
          $or: [{ IsActive: { $exists: false } }, { IsActive: true }],
          phone: this.state.SearchBox,
        },
      ],
    };

    let search = btoa(JSON.stringify(query));

    history.push({
      pathname: `/admin/ListProspects`,
      search: `Search=${search}`,
    });
  };

  handleChnagePass = () => {
    const { history } = this.props;
    history.push("/admin/ChangePassword");
  };

  render() {
    const { classes, user , permitPages } = this.props;

    const isMobile = (window.innerWidth <= 760)

    const { open, profilePopupOpen, batteryCss, time, amPm } = this.state;
    return (
      <div className="navbar__search">


        {/* <Clock /> */}
 
        

        <div className={classes.manager}>
          <Hidden implementation="css">
            {!isMobile &&
              <PushNotification {...this.props} />
            }

            <Button
              buttonRef={(node) => {
                this.anchorEl = node;
              }}
              color={"transparent"}
              aria-label="Person"
              aria-haspopup="true"
              onClick={this.handleToggleProfile}
            >
              <Person className={classes.icons} />
              {user && user.username}

            </Button>

            <Poppers
              open={profilePopupOpen}
              anchorEl={this.anchorEl}
              transition
              disablePortal
              className={
                classNames({ [classes.popperClose]: !profilePopupOpen }) +
                " " +
                classes.pooperNav
              }
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList role="menu">
                        {/* <NavLink to="/admin/user">
                        <MenuItem
                          onClick={this.handleClose}
                          className={classes.dropdownItem}
                        >
                          Profile
                        </MenuItem>
                      </NavLink>
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        Settings
                      </MenuItem>
                      <MenuItem
                        onClick={this.handleClose}
                        className={classes.dropdownItem}
                      >
                        Activity
                      </MenuItem> */}

                       
                        {["manager", "owner"].indexOf(user && user.role) > -1 &&
                          <NavLink to="/admin/addnotification">
                            <MenuItem
                              onClick={this.handleClose}
                              className={classes.dropdownItem}
                            >
                              Manage Notification
                            </MenuItem>
                          </NavLink>
                        }
                        {["manager", "owner"].indexOf(user && user.role) > -1 &&
                          <NavLink to="/admin/release">
                            <MenuItem
                              onClick={this.handleClose}
                              className={classes.dropdownItem}
                            >
                              Product Release
                            </MenuItem>
                          </NavLink>

                        }
                      

                        {(user.is_superuser == 1) &&
                          <NavLink to="/admin/support">
                            <MenuItem
                              onClick={this.handleClose}
                              className={classes.dropdownItem}
                            >
                              Support
                            </MenuItem>
                          </NavLink>
                        }


                        {(user.is_superuser == 1) &&
                          <NavLink to="/admin/info_link">
                            <MenuItem
                              onClick={this.handleClose}
                              className={classes.dropdownItem}
                            >
                              Info Link
                            </MenuItem>
                          </NavLink>
                        }




                        {["manager", "owner"].indexOf(user && user.role) > -1 &&
                          <NavLink to="/admin/Configuration">
                            <MenuItem
                              onClick={this.handleClose}
                              className={classes.dropdownItem}
                            >
                              Configuration
                            </MenuItem>
                          </NavLink>}
                        {/* ["manager", "owner"].indexOf(user && user.role) > -1 &&
                          <NavLink to="/admin/CallingSetupList">
                            <MenuItem
                              onClick={this.handleClose}
                              className={classes.dropdownItem}
                            >
                              Calling Setup
                            </MenuItem>
                    </NavLink> */}


                        {/* <MenuItem className={classes.dropdownItem}>
                          {user && user.username}
                        </MenuItem> */}
                        <a href="https://betyphon.tawk.help/" target="_blank">
                          <MenuItem
                            className={classes.dropdownItem}
                          >
                            Help

                          </MenuItem>
                        </a>
                        <MenuItem
                          onClick={this.handleChnagePass}
                          className={classes.dropdownItem}
                        >
                          Change Password
                        </MenuItem>


                        <NavLink to="/admin/user">
                          <MenuItem
                            onClick={this.handleClose}
                            className={classes.dropdownItem}
                          >
                            Profile
                          </MenuItem>
                        </NavLink>


                        {/* ["manager", "owner"].indexOf(user && user.role) > -1 &&
                          <NavLink to="/admin/ApplicationSetup">
                            <MenuItem
                              onClick={this.handleClose}
                              className={classes.dropdownItem}
                            >
                              Application Setup
                            </MenuItem>
                          </NavLink> */
                        }

                        { permitPages && permitPages['Dialling'] &&
                          <NavLink to="/admin/dialling">
                            <MenuItem
                              onClick={this.handleClose}
                              className={classes.dropdownItem}
                            >
                              Dialling
                            </MenuItem>
                          </NavLink>
                        }

                        <MenuItem
                          onClick={this.logout}
                          className={classes.dropdownItem}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>


          </Hidden>
        </div>
      </div>
    );
  }
}
const mapstatetoProps = (state) => {
  return {};
};

//add dispatch to props
const mapDispatchtoProps = (dispatch) => {
  return {
    setSearchValToRedux: (value) =>
      dispatch(searchVal({ SearchBoxVal: value })),
  };
};

//export default withStyles(headerLinksStyle)(HeaderLinks);

export default connect(
  mapstatetoProps,
  mapDispatchtoProps
)(withStyles(headerLinksStyle)(HeaderLinks));
